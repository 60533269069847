import gql from 'graphql-tag';

import { DetailsPanelUserFragment } from './fragments/DetailsPanelUserFragment.fragment';

export const DetailsPanelContentContributorsQuery = gql`
	query DetailsPanelContentContributorsQuery($contentId: ID!, $contributorLimit: Int!) {
		contentContributors(id: $contentId, limit: $contributorLimit) {
			nodes {
				...DetailsPanelUserFragment
			}
		}
	}
	${DetailsPanelUserFragment}
`;

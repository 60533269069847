import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import TagIcon from '@atlaskit/icon/core/tag';
import { Stack } from '@atlaskit/primitives';

import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { EditLabelDialogLoader } from '@confluence/labels';

import { useContentLabels } from '../hooks/useContentLabels';

import { InlineRetryError } from './InlineRetryError';
import { DetailRow } from './DetailRow';
import { Labels } from './Labels';

const i18n = defineMessages({
	labelsSectionTitle: {
		id: 'details-panel.labels-section.title',
		defaultMessage: 'Labels',
		description: 'Title for section containing labels related to content',
	},
	addButtonLabel: {
		id: 'details-panel.labels-section.add-button-label',
		defaultMessage: 'Add label',
		description: 'aria-label for button to add label for content',
	},
});

export const LabelsSection: React.FC = () => {
	const { formatMessage } = useIntl();
	const [contentId = ''] = usePageContentId();
	const [spaceKey = ''] = usePageSpaceKey();
	const { showModal } = useDialogs();
	const { data, error, refetch, handleDeleteLabelClick, loading } = useContentLabels();
	const { labels, hasEditPermissions } = data;

	const renderLabelsSectionContent = () => {
		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return hasEditPermissions ? (
			<IconButton
				appearance="subtle"
				icon={AddIcon}
				label={formatMessage(i18n.addButtonLabel)}
				onClick={() =>
					showModal(EditLabelDialogLoader, {
						contentId,
						spaceKey,
						source: 'detailsPanel',
					})
				}
				isDisabled={!contentId || !spaceKey || loading}
				testId="add-label-button"
			/>
		) : null;
	};

	return (
		<Stack space="space.050" testId="labels-section">
			<DetailRow itemName={formatMessage(i18n.labelsSectionTitle)} icon={<TagIcon label="" />}>
				{renderLabelsSectionContent()}
			</DetailRow>
			{labels.length > 0 && (
				<Labels
					labels={labels}
					refetch={refetch}
					hasEditPermissions={hasEditPermissions}
					handleDeleteLabelClick={handleDeleteLabelClick}
				/>
			)}
		</Stack>
	);
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ShieldIcon from '@atlaskit/icon/core/shield';
import Skeleton from '@atlaskit/skeleton';

import {
	ContentClassificationTag,
	useIsDataClassificationEnabledForOrg,
	FlagStatus,
} from '@confluence/data-classification';
import { usePageContentId, useContentType } from '@confluence/page-context';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';

import { useContentClassification } from '../hooks/useContentClassification';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { ContentClassifier } from './ContentClassifier';

const i18n = defineMessages({
	classificationSection: {
		id: 'details-panel.activity-section.classification-row',
		defaultMessage: 'Classification',
		description:
			"A title that appears next to the content's classification (highly confidential, confidential, internal, public)",
	},
});

export const ClassificationRow = () => {
	const { formatMessage } = useIntl();
	const [contentType] = useContentType();
	const [contentId] = usePageContentId();
	const pageMode = useGetPageMode();
	const { data, error, loading, refetch } = useContentClassification();

	const { isEnabled: isDataClassificationEnabled } = useIsDataClassificationEnabledForOrg();

	const isDataClassificationEmptyOnViewPage = pageMode === PageMode.VIEW && !data.classification;
	const isClassificationRowVisible =
		isDataClassificationEnabled && !isDataClassificationEmptyOnViewPage;

	const renderClassificationItem = () => {
		if (loading) {
			return (
				<Skeleton width="80px" height="24px" borderRadius={3} testId="classification-row-loading" />
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return pageMode === PageMode.VIEW || data.flagStatus === FlagStatus.LOADING ? (
			<ContentClassificationTag
				contentId={contentId ?? ''}
				contentType={contentType ?? ''}
				contentClassificationMetadata={data}
			/>
		) : (
			<ContentClassifier contentClassificationMetadata={data} />
		);
	};

	return isClassificationRowVisible ? (
		<DetailRow itemName={formatMessage(i18n.classificationSection)} icon={<ShieldIcon label="" />}>
			{renderClassificationItem()}
		</DetailRow>
	) : null;
};

import type { DetailsPanelContentCreatedByQuery } from '../queries/__types__/DetailsPanelContentCreatedByQuery';
import { hasAccountId } from '../userUtils';

export const normalizeContentCreatorData = (data?: DetailsPanelContentCreatedByQuery) => {
	const createdBy = data?.content?.nodes?.[0]?.history?.createdBy;

	return {
		accountId: hasAccountId(createdBy) ? createdBy.accountId : '',
		displayName: createdBy?.displayName ?? '',
		profilePicturePath: createdBy?.profilePicture?.path,
	};
};

import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useContentClassification as useContentClassificationMetadata } from '@confluence/data-classification/entry-points/queries';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode, ContentStatus } from '@confluence/page-utils/entry-points/enums';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

export const useContentClassification = () => {
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const pageMode = useGetPageMode();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const contentClassificationMetadata = useContentClassificationMetadata({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext:
			contentStatus === ContentStatus.DRAFT || pageMode === PageMode.EDIT
				? 'draft-only'
				: 'current-and-draft',
		shouldSkipQueries: !contentStatus,
		shouldReadFromQueries: true,
	});

	const isContentClassificationMetadataLoading =
		contentClassificationMetadata.contentLevelLoading ||
		contentClassificationMetadata.spaceLevelLoading;

	return {
		data: contentClassificationMetadata,
		loading: isContentStatusLoading || isContentClassificationMetadataLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	};
};

import { useSessionData } from '@confluence/session-data';
import { ContentStatus } from '@confluence/page-utils/entry-points/enums';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { useSpaceAdmin } from './useSpaceAdmin';
import { useContentOwner } from './useContentOwner';

type GetRefetchProps = {
	isContentStatusError: boolean;
	isContentOwnerError: boolean;
	refetchContentStatus: () => void;
	refetchContentOwner: () => void;
	refetchSpaceAdmin: () => void;
};

const getRefetch = ({
	isContentStatusError,
	isContentOwnerError,
	refetchContentStatus,
	refetchContentOwner,
	refetchSpaceAdmin,
}: GetRefetchProps) => {
	if (isContentStatusError) {
		return refetchContentStatus;
	}

	if (isContentOwnerError) {
		return refetchContentOwner;
	}

	return refetchSpaceAdmin;
};

export const useIsChangeOwnerAvailable = () => {
	const { userId } = useSessionData();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
		type: contentType,
	} = useContentStatus();

	const {
		data: contentOwnerData,
		loading: isContentOwnerLoading,
		error: contentOwnerError,
		refetch: refetchContentOwner,
	} = useContentOwner();

	const {
		data: spaceAdminData,
		loading: isSpaceAdminLoading,
		error: spaceAdminError,
		refetch: refetchSpaceAdmin,
	} = useSpaceAdmin();

	const isNotBlogpost = contentType !== 'blogpost';
	const isNotDraft = contentStatus !== ContentStatus.DRAFT;
	const isNotArchived = contentStatus !== ContentStatus.ARCHIVED;
	const isCurrentUserContentOwner = userId !== null && userId === contentOwnerData.accountId;
	const isChangeOwnerAvailable =
		isNotBlogpost &&
		isNotArchived &&
		isNotDraft &&
		(spaceAdminData.isCurrentUserSpaceAdmin || isCurrentUserContentOwner);

	return {
		isChangeOwnerAvailable,
		loading: isContentStatusLoading || isContentOwnerLoading || isSpaceAdminLoading,
		error: contentStatusError || contentOwnerError || spaceAdminError,
		refetch: getRefetch({
			isContentOwnerError: !!contentOwnerError,
			isContentStatusError: !!contentStatusError,
			refetchContentOwner,
			refetchContentStatus,
			refetchSpaceAdmin,
		}),
	};
};

import type { DetailsPanelContentLabelsQuery } from '../queries/__types__/DetailsPanelContentLabelsQuery';

export const normalizeContentLabelsData = (data?: DetailsPanelContentLabelsQuery) => {
	const labels = data?.singleContent?.labels;
	const firstNode = data?.content?.nodes?.[0];
	const operations = firstNode?.operations ?? [];
	const type = firstNode?.type;

	const hasEditPermissions = Boolean(
		operations?.find((item) => item?.operation === 'update' && item?.targetType === type),
	);

	return {
		labels: labels?.nodes ?? [],
		hasEditPermissions,
	};
};

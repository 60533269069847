import gql from 'graphql-tag';

export const SpaceHomepageIdQuery = gql`
	query SpaceHomepageIdQuery($spaceKey: String) {
		space(key: $spaceKey) {
			homepage {
				id
			}
		}
	}
`;

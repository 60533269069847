import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import { SpaceHomepageIdQuery } from './SpaceHomepageIdQuery.graphql';
import type {
	SpaceHomepageIdQueryVariables,
	SpaceHomepageIdQuery as SpaceHomepageIdQueryType,
} from './__types__/SpaceHomepageIdQuery';

export const useSpaceHomepageId = (spaceKey?: string): string | undefined => {
	const [pageContextSpaceKey] = usePageSpaceKey();
	spaceKey = spaceKey ?? pageContextSpaceKey;
	const { data } = useQuery<SpaceHomepageIdQueryType, SpaceHomepageIdQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceHomepageIdQuery,
		{
			variables: { spaceKey },
			skip: !spaceKey,
		},
	);
	return data?.space?.homepage?.id || undefined;
};

import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentLabelsQuery } from '../queries/DetailsPanelContentLabelsQuery.graphql';
import { DetailsPanelContentLabelDeleteMutation } from '../queries/DetailsPanelContentLabelDeleteMutation.graphql';
import type {
	DetailsPanelContentLabelsQuery as ContentLabelsQuery,
	DetailsPanelContentLabelsQueryVariables as ContentLabelsQueryVariables,
} from '../queries/__types__/DetailsPanelContentLabelsQuery';
import type {
	DetailsPanelContentLabelDeleteMutation as ContentLabelDeleteMutation,
	DetailsPanelContentLabelDeleteMutationVariables as ContentLabelDeleteMutationVariables,
} from '../queries/__types__/DetailsPanelContentLabelDeleteMutation';
import { normalizeContentLabelsData } from '../normalizers/normalizeContentLabelsData';

export const useContentLabels = () => {
	const [contentId = ''] = usePageContentId();

	const { data, loading, error, refetch } = useQuery<
		ContentLabelsQuery,
		ContentLabelsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentLabelsQuery,
		{
			variables: {
				contentId,
			},
			skip: !contentId,
		},
	);

	const [handleDeleteLabelClick] = useMutation<
		ContentLabelDeleteMutation,
		ContentLabelDeleteMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentLabelDeleteMutation,
	);

	const normalizedData = useMemo(() => normalizeContentLabelsData(data), [data]);

	return {
		data: normalizedData,
		handleDeleteLabelClick,
		loading,
		error,
		refetch,
	};
};

import gql from 'graphql-tag';

export const DetailsPanelContentAttachmentsQuery = gql`
	query DetailsPanelContentAttachmentsQuery($contentId: ID!, $status: String!) {
		content(id: $contentId, status: [$status]) {
			nodes {
				id
				attachments {
					count
				}
			}
		}
	}
`;

import React from 'react';

import { Stack } from '@atlaskit/primitives';

import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { ScreenEvent } from '@confluence/analytics';
import {
	SIDEBAR_DETAILS_PANEL_LOAD_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';

import { AttributesSection } from './components/AttributesSection';
import { UserSection } from './components/UserSection';
import { InfoSection } from './components/InfoSection';
import { PageModeSection } from './components/PageModeSection';
import { ConnectionsSection } from './components/ConnectionsSection';
import { useElementVisibility, ELEMENTS } from './hooks/useIsElementVisible';

const DetailsPanelComponent = () => {
	const { isElementVisible } = useElementVisibility();
	return (
		<>
			<Stack testId="details-panel-body">
				<UserSection />
				{isElementVisible(ELEMENTS.SECTIONS.PAGE_MODE) &&
					fg('confluence_frontend_page_mode_in_details_panel') && <PageModeSection />}
				<AttributesSection />
				{isElementVisible(ELEMENTS.SECTIONS.PROPERTIES) && <InfoSection />}
				<ConnectionsSection />
			</Stack>
			<ExperienceSuccess name={SIDEBAR_DETAILS_PANEL_LOAD_EXPERIENCE} />
			<ScreenEvent name="detailsPanelScreen" id="detailsPanelScreen" />
		</>
	);
};

export const DetailsPanelBody = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(DetailsPanelComponent);

import gql from 'graphql-tag';

import { UserPermissionFragment } from '@confluence/external-collab-ui/entry-points/UserPermissionFragment';

export const DetailsPanelContentViewsQuery = gql`
	query DetailsPanelContentViewsQuery($contentId: ID!, $isDraft: Boolean!) {
		contentAnalyticsViewers(contentId: $contentId) @skip(if: $isDraft) {
			count
		}
		...UserPermissionFragment
	}
	${UserPermissionFragment}
`;

import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';
import { DetailsPanelContentCreatedDateQuery } from '@confluence/details-panel-shared-queries';
import type {
	DetailsPanelContentCreatedDateQueryType as ContentCreatedDateQuery,
	DetailsPanelContentCreatedDateQueryVariables as ContentCreatedDateQueryVariables,
} from '@confluence/details-panel-shared-queries';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { normalizeContentCreatedData } from '../normalizers/normalizeContentCreatedData';

export const useContentCreatedDate = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch } = useQuery<
		ContentCreatedDateQuery,
		ContentCreatedDateQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentCreatedDateQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentCreatedData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};

import type { ApolloError } from 'apollo-client';
import { isApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

export const PERMISSION_ERROR_CODES = [400, 401, 403];

export const errorContainsCodes = (error: ApolloError | GraphQLError, expectedCodes: number[]) => {
	if (isApolloError(error)) {
		return error.graphQLErrors.some((e) => expectedCodes.includes(e?.extensions?.statusCode));
	} else {
		return expectedCodes.includes(error?.extensions?.statusCode);
	}
};

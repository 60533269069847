import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ArrowDownLeftIcon from '@atlaskit/icon/core/arrow-down-left';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { usePageContentId } from '@confluence/page-context';

import { useContentIncomingLinks } from '../hooks/useContentIncomingLinks';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	incomingLinks: {
		id: 'details-panel.links-section.incoming-links-row',
		defaultMessage: 'Incoming links',
		description:
			'Text that appears next to a number indicating the number of incoming links on specific content',
	},
});

export const IncomingLinksRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();
	const { data, error, refetch } = useContentIncomingLinks();
	const { incomingLinksCount } = data;

	if (!incomingLinksCount && !error) {
		return null;
	}

	return (
		<DetailRow itemName={formatMessage(i18n.incomingLinks)} icon={<ArrowDownLeftIcon label="" />}>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<SubtleLink href={`${CONTEXT_PATH}/pages/viewinfo.action?pageId=${contentId}`}>
					{incomingLinksCount}
				</SubtleLink>
			)}
		</DetailRow>
	);
};

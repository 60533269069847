import { getAtlassianPeopleProfileUrl } from '@confluence/profile';
import { CONTEXT_PATH } from '@confluence/named-routes';

import type { DetailsPanelContentContributorsQuery_contentContributors_nodes as ContentContributorNode } from '../queries/__types__/DetailsPanelContentContributorsQuery';
import { hasAccountId } from '../userUtils';

export const getFormattedNodes = ({
	nodes,
	ownerAccountId,
}: {
	nodes: (ContentContributorNode | null)[];
	ownerAccountId: string | null;
}) =>
	nodes
		.filter((contributor): contributor is ContentContributorNode => {
			if (!contributor) return false; // Exclude null contributors
			if (!hasAccountId(contributor)) return true; // Include contributors without an accountId, as they can't be the owner
			return contributor.accountId !== ownerAccountId; // Exclude contributors whose accountId matches the owner's accountId
		})
		.map((contributor) => {
			const accountId = hasAccountId(contributor) ? contributor.accountId : null;
			return {
				name: contributor.displayName || '',
				href: getAtlassianPeopleProfileUrl(accountId, '', CONTEXT_PATH),
				src: contributor.profilePicture?.path || '',
			};
		});

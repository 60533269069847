import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';

import { ReadTime } from '@confluence/read-time';
import { usePageContentId } from '@confluence/page-context';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';

import { useDetailsPanelEditorState } from '../useDetailsPanelEditorState';

import { DetailRow } from './DetailRow';

const i18n = defineMessages({
	length: {
		id: 'details-panel.properties-section.length-row',
		defaultMessage: 'Length',
		description: "A title that accompanies the content's read time in minutes",
	},
});

export const LengthRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();
	const pageMode = useGetPageMode();
	const { editorApi } = useDetailsPanelEditorState();

	return (
		<DetailRow itemName={formatMessage(i18n.length)} icon={<ListBulletedIcon label="" />}>
			<ReadTime
				editorApi={editorApi}
				contentId={contentId ?? ''}
				isAbbreviatedReadTime={false}
				isInEditor={pageMode !== PageMode.VIEW}
				isDetailsPanel
				showWordCount
			/>
		</DetailRow>
	);
};

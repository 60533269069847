import { defineMessages } from 'react-intl-next';

import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';

import { WorkflowPanelBody, WorkflowIcon } from './WorkflowPanelBody';

const i18n = defineMessages({
	workflowsPanelHeaderLabel: {
		id: 'advanced-workflows.panel.header.label',
		defaultMessage: 'Workflows',
		description: 'Label for the workflows panel header',
	},
});

export const useWorkflowsPanel = () => {
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar }] = useObjectSidebar();

	const showWorkflowsPanel = () => {
		showObjectSidebar(
			{
				id: PanelName.WorkflowsPanel,
				headerComponentElements: {
					HeaderIcon: WorkflowIcon,
					headerLabel: i18n.workflowsPanelHeaderLabel,
				},
				BodyComponent: WorkflowPanelBody,
			},
			'push',
		);
	};

	return {
		isWorkflowsPanelShown: isObjectSidebarShown && panel?.id === PanelName.WorkflowsPanel,
		showWorkflowsPanel,
	};
};

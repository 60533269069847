import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import IssuesIcon from '@atlaskit/icon/core/issues';

const i18n = defineMessages({
	jiraWorkItemsPanelHeaderIcon: {
		id: 'details-panel.jira-work-items.header.icon.label',
		defaultMessage: 'Jira work items Icon',
		description: 'Label for the Jira work items panel header icon',
	},
});

export const JiraWorkItemsPanelIcon = () => {
	const { formatMessage } = useIntl();
	return <IssuesIcon label={formatMessage(i18n.jiraWorkItemsPanelHeaderIcon)} />;
};

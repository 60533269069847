import React from 'react';

import Spinner from '@atlaskit/spinner';
import { FadeIn } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';

import { fg } from '@confluence/feature-gating';
import { useLoomEntryPointVariant } from '@confluence/loom-utils';

import { LoomVideoIcon } from './VideoIcon';

const SpinnerContainerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

const buttonSpacerStyles = xcss({
	minWidth: '30px',
});

export const LoomPageHeaderPlaceholder = ({ isCircle }: { isCircle?: boolean }) => {
	const { entryPointVariant } = useLoomEntryPointVariant();
	if (fg('confluence_frontend_object_sidebar_ssr')) {
		if (entryPointVariant === 'DISABLED') {
			return null;
		} else {
			return (
				<Box xcss={buttonSpacerStyles}>
					<IconButton
						appearance="subtle"
						shape={isCircle ? 'circle' : 'default'}
						icon={LoomVideoIcon}
						testId="page-header-loom-entry-point-loading-icon"
						data-vc="loom-button"
						isDisabled
						label=""
					/>
				</Box>
			);
		}
	}

	return (
		<Box xcss={SpinnerContainerStyles}>
			<FadeIn>
				{(props) => (
					<span {...props}>
						<Spinner
							size="small"
							interactionName={
								fg('ufo-manual-experimental-holds-confluence')
									? 'page-header-loom-entry-point-loading-icon'
									: undefined
							}
							testId="page-header-loom-entry-point-loading-icon"
							data-vc="loom-button"
						/>
					</span>
				)}
			</FadeIn>
		</Box>
	);
};

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { CreatedRow } from './CreatedRow';
import { LengthRow } from './LengthRow';
import { CreatorRow } from './CreatorRow';
import { ViewsRowWrapper as ViewsRow } from './ViewsRow';
import { ContributorsRow } from './ContributorsRow';

const i18n = defineMessages({
	attributesSectionTitle: {
		id: 'details-panel.attributes-section.title',
		defaultMessage: 'Attributes',
		description: 'Title for the section containing attributes related to content',
	},
});

const attributesSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

export const AttributesSection = () => (
	<Stack testId="attributes-section" xcss={attributesSectionStyles}>
		<FormattedMessage {...i18n.attributesSectionTitle} tagName="h4" />
		<Stack>
			<ViewsRow />
			<LengthRow />
			<ContributorsRow />
			<CreatorRow />
			<CreatedRow />
		</Stack>
	</Stack>
);

import React, { type FC } from 'react';

import { Inline, Text, xcss } from '@atlaskit/primitives';

const detailRowStyles = xcss({
	marginTop: 'space.100',
	height: 'size.200',
});

type DetailRowProps = {
	icon: React.JSX.Element;
	itemName: string;
	children?: React.ReactNode;
};

export const DetailRow: FC<DetailRowProps> = ({ icon, itemName, children }) => {
	return (
		<Inline spread="space-between" alignBlock="center" xcss={detailRowStyles}>
			<Inline space="space.100" alignBlock="center">
				{icon}
				<Text>{itemName}</Text>
			</Inline>
			{children}
		</Inline>
	);
};

import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import IssuesIcon from '@atlaskit/icon/core/issues';

import { usePageContentId } from '@confluence/page-context';
import {
	EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { RequireLegacyWRM } from '@confluence/wrm';
import {
	WebItemLocation,
	PAGE_METADATA_BANNER,
	type FormattedWebItem,
	type WebItemLocationChildrenFn,
} from '@confluence/web-item-location';
import { CONTENT_METADATA_JIRA_ID } from '@confluence/web-item';
import { WebItemList } from '@confluence/web-item-list';
import { createSingleQueryParamHook } from '@confluence/route-manager';

import { DetailRow } from './DetailRow';

const i18n = defineMessages({
	jiraIssues: {
		id: 'details-panel.links-section.jira-issues-row',
		defaultMessage: 'Jira work items',
		description:
			'Text that appears next to a number indicating the number of Jira links on specific content',
	},
});

// Needed to load the inline dialog
const WRM_MODULES = [
	'wrc!com.atlassian.confluence.plugins.confluence-jira-metadata:confluence-jira-metadata-resources',
];

const useVersionOverride = createSingleQueryParamHook('versionOverride');

export const JiraIssuesRow: FC = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();

	// WebItemLocation must use versionOverride, and not just version
	const versionOverrideParam: string | undefined = useVersionOverride();
	const versionOverride: number | undefined = versionOverrideParam
		? parseInt(versionOverrideParam)
		: undefined;

	const renderWebItem = () => {
		return (
			<>
				<ExperienceStart name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
				<WebItemLocation
					tagName="span"
					location={PAGE_METADATA_BANNER}
					contentId={contentId}
					version={versionOverride}
				>
					{
						(({ webItems }) => {
							const jiraItemOnly = webItems.filter(
								(item: FormattedWebItem) => item.id === CONTENT_METADATA_JIRA_ID,
							);

							const jiraWebItemList = (
								<>
									<WebItemList
										pageId={contentId}
										webItems={jiraItemOnly}
										id="page-metadata-banner-items"
									/>
									<ExperienceSuccess name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
								</>
							);
							return <RequireLegacyWRM wrm={WRM_MODULES}>{() => jiraWebItemList}</RequireLegacyWRM>;
						}) as WebItemLocationChildrenFn
					}
				</WebItemLocation>
			</>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.jiraIssues)} icon={<IssuesIcon label="" />}>
			{renderWebItem()}
		</DetailRow>
	);
};

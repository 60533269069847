import React, { useCallback, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import InformationIcon from '@atlaskit/icon/core/information-circle';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';

import { fg } from '@confluence/feature-gating';
import { useContentType } from '@confluence/page-context';
import { OperationTypes, useCanPerformContentOperation } from '@confluence/entity-operations';
import {
	SIDEBAR_DETAILS_PANEL_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useShowDetailsPanel, preloadRedactionsQueries } from '@confluence/details-panel';

import { OBJECT_SIDEBAR_TOUR_TARGETS } from '../objectSidebarTourTargets';

const i18n = defineMessages({
	openDetailsPanelLabel: {
		id: 'object-sidebar-components.open.details.panel.label',
		defaultMessage: 'Open Details Panel',
		description: 'Label for button to open the details panel',
	},
	showDetailsLabel: {
		id: 'object-sidebar-components.show.details.label',
		description:
			"The text is shown in a tooltip when the Object Sidebar's Details button is hovered.",
		defaultMessage: 'Show details',
	},
});

export const DetailsPanelButton = ({ contentId }: { contentId: string }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { showDetailsPanel } = useShowDetailsPanel();
	const [contentTypeFromState] = useContentType();

	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType: contentTypeFromState,
		operation: OperationTypes.UPDATE,
	});

	const handleClickDetailsButton = useCallback(() => {
		experienceTracker.start({
			name: SIDEBAR_DETAILS_PANEL_LOAD_EXPERIENCE,
		});
		showDetailsPanel();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'detailsPanel',
				source: 'contentWrapper',
			},
		}).fire();
	}, [showDetailsPanel, createAnalyticsEvent, experienceTracker]);

	// Avoids CLS from redactions feature entitlement query in DetailsPanel
	const onHoverDetailsButton = useCallback(() => {
		if (fg('dlp_cc-redactions-metadata-modal')) {
			void preloadRedactionsQueries({
				contentId,
				canUpdateContent: !!canUpdateContent,
			});
		}
	}, [contentId, canUpdateContent]);

	return (
		<Tooltip position="left" content={formatMessage(i18n.showDetailsLabel)}>
			<SpotlightTarget name={OBJECT_SIDEBAR_TOUR_TARGETS.SIDEBAR_DETAILS_BUTTON}>
				<Box onMouseEnter={onHoverDetailsButton} data-vc="object-sidebar-details-button">
					<IconButton
						onClick={handleClickDetailsButton}
						appearance="subtle"
						shape={fg('ai-smart-button-team-25') ? 'default' : 'circle'}
						icon={InformationIcon}
						label={formatMessage(i18n.openDetailsPanelLabel)}
						testId="sidebar-details-button"
					/>
				</Box>
			</SpotlightTarget>
		</Tooltip>
	);
};

// Projects and Issue Types aratar URLs are returned wrongly for Stargate requests.
// This function corrects the URLs.
// This can be removed once the Stargate issue is fixed. Ticket: https://donut-world-dogfood.atlassian.net/browse/MCRTB-1099
export const transformStargateUrl = (url: string): string => {
	try {
		if (url && url.includes('ex/jira') && !url.includes('gateway/api')) {
			const urlObject = new URL(url);
			let urlPathArray = urlObject.pathname.split('/');
			urlPathArray = ['gateway', 'api', ...urlPathArray.slice(1)];
			return `${urlObject.origin}/${urlPathArray.join('/')}${urlObject.search}`;
		}
	} catch (e) {
		return url;
	}
	return url;
};

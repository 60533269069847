import React from 'react';

import { type AIAction, useAISmartButton } from '@atlassian/ai-smart-button-api';

import { CommentCountsProvider } from '@confluence/comment-context';

import { useAIConsumptionViewPage } from '../hooks/useAIConsumptionViewPage';

/**
 * Currently confluence is only aware "editor" and "view-page" sources.
 * Show first actions from "editor" source and then from "view-page" source.
 * If actions from any other source is there, then it's position won't be changed,
 * 	only editor actions will be placed at top.
 *
 * Order of actions from same source will be maintained.
 */
export function actionsSorter(actions: AIAction[]) {
	return actions.sort((a, b) => {
		// If source is same then maintain the order.
		if (a.sourceId === b.sourceId) {
			return 0;
		}
		return a.sourceId === 'editor' ? -1 : 1;
	});
}

type AISmartButtonStateHandlerProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	lastModifiedDate?: string | null;
};

function AISmartButtonStateHandlerInner({
	contentId,
	contentType,
	spaceKey,
	lastModifiedDate,
}: AISmartButtonStateHandlerProps) {
	const [{ context }, { setSelectors }] = useAISmartButton();

	const actionsSelector = React.useCallback(
		(actions: AIAction[]) => {
			/**
			 * When there is selection in editor, need to show only editor actions.
			 * And if context with reference is there, that means there is selection in editor.
			 * So show only editor actions.
			 */
			if (context?.reference) {
				return actions.filter((action) => action.sourceId === 'editor');
			}
			return actionsSorter(actions);
		},
		[context],
	);

	React.useEffect(() => {
		setSelectors({ actionsSelector });
	}, [actionsSelector, setSelectors]);

	useAIConsumptionViewPage({
		contentId,
		contentType: contentType ?? '',
		lastModifiedDate,
		spaceKey: spaceKey ?? '',
	});

	return null;
}

export const AISmartButtonStateHandler = (props: AISmartButtonStateHandlerProps) => {
	return (
		<CommentCountsProvider contentId={props.contentId}>
			<AISmartButtonStateHandlerInner {...props} />
		</CommentCountsProvider>
	);
};

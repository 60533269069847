import React from 'react';
import type { ApolloQueryResult } from 'apollo-client';
import type { ExecutionResult, MutationFunctionOptions } from 'react-apollo';

import { Flex, xcss } from '@atlaskit/primitives';
import Tag, { SimpleTag } from '@atlaskit/tag';

import { usePageContentId } from '@confluence/page-context';

import type {
	DetailsPanelContentLabelsQuery,
	DetailsPanelContentLabelsQuery_singleContent_labels_nodes as LabelNode,
} from '../queries/__types__/DetailsPanelContentLabelsQuery';
import type {
	DetailsPanelContentLabelDeleteMutation,
	DetailsPanelContentLabelDeleteMutationVariables,
} from '../queries/__types__/DetailsPanelContentLabelDeleteMutation';

const labelsStyles = xcss({
	marginLeft: 'space.250',
});

type LabelProps = {
	label: string;
	hasEditPermissions: boolean;
	refetch: () => Promise<ApolloQueryResult<DetailsPanelContentLabelsQuery>>;
	handleDeleteLabelClick: (
		options?: MutationFunctionOptions<
			DetailsPanelContentLabelDeleteMutation,
			DetailsPanelContentLabelDeleteMutationVariables
		>,
	) => Promise<ExecutionResult<DetailsPanelContentLabelDeleteMutation>>;
};

const Label = ({ label, hasEditPermissions, refetch, handleDeleteLabelClick }: LabelProps) => {
	const [contentId = ''] = usePageContentId();

	const handleRemove = async () => {
		await handleDeleteLabelClick({
			variables: {
				contentId,
				label,
			},
		});

		await refetch();
	};

	return hasEditPermissions ? (
		<Tag key={label} text={label} onAfterRemoveAction={handleRemove} />
	) : (
		<SimpleTag testId="readonly-label" key={label} text={label} />
	);
};

type LabelsProps = Omit<LabelProps, 'label'> & {
	labels: (LabelNode | null)[];
};

export const Labels = ({
	labels,
	refetch,
	hasEditPermissions,
	handleDeleteLabelClick,
}: LabelsProps) => (
	<Flex xcss={labelsStyles} direction="row" alignItems="center" wrap="wrap">
		{labels.map((labelNode) =>
			labelNode?.label ? (
				<Label
					refetch={refetch}
					key={labelNode.label}
					label={labelNode.label}
					hasEditPermissions={hasEditPermissions}
					handleDeleteLabelClick={handleDeleteLabelClick}
				/>
			) : null,
		)}
	</Flex>
);

import gql from 'graphql-tag';

import { DetailsPanelUserFragment } from './fragments/DetailsPanelUserFragment.fragment';

export const DetailsPanelContentCreatedByQuery = gql`
	query DetailsPanelContentCreatedByQuery($contentId: ID!, $status: String!) {
		content(id: $contentId, status: [$status]) {
			nodes {
				id
				history {
					createdBy {
						...DetailsPanelUserFragment
					}
				}
			}
		}
	}
	${DetailsPanelUserFragment}
`;

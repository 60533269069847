import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageSpaceKey } from '@confluence/page-context';

import { DetailsPanelSpaceAdminQuery } from '../queries/DetailsPanelSpaceAdminQuery.graphql';
import type {
	DetailsPanelSpaceAdminQuery as SpaceAdminQuery,
	DetailsPanelSpaceAdminQueryVariables as SpaceAdminQueryVariables,
} from '../queries/__types__/DetailsPanelSpaceAdminQuery';
import { normalizeSpaceAdminData } from '../normalizers/normalizeSpaceAdminData';

export const useSpaceAdmin = () => {
	const [spaceKey] = usePageSpaceKey();

	const { data, loading, error, refetch } = useQuery<SpaceAdminQuery, SpaceAdminQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelSpaceAdminQuery,
		{
			skip: !spaceKey,
			variables: {
				spaceKey: spaceKey ?? '',
			},
		},
	);

	const normalizedData = useMemo(() => normalizeSpaceAdminData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error,
		refetch,
	};
};

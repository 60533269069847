import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from '@apollo/client';

import { Stack, xcss, Inline, Text, Box } from '@atlaskit/primitives';
import DataFlowIcon from '@atlaskit/icon/core/data-flow';
import Heading from '@atlaskit/heading';
import IssueIcon from '@atlaskit/icon/core/issue';
import ClockIcon from '@atlaskit/icon/core/clock';
import PersonIcon from '@atlaskit/icon/core/person';
import Link from '@atlaskit/link';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import PriorityTrivialIcon from '@atlaskit/icon/core/priority-trivial';
import Button from '@atlaskit/button/new';

import { ConfluencePageAri } from '@atlassian/ari';

import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { usePageContentId } from '@confluence/page-context';

import { ApproverAvatar } from './ApproverAvatar';

const WorkflowPanelBodyQuery = gql`
	query WorkflowPanelBodyQuery($contentId: ID!) {
		confluence_workflowApplicationsByContentId(contentId: $contentId, first: 50) {
			nodes {
				currentWorkflowStatus {
					id
					displayProperties {
						name
						color
					}
				}
				availableTransitions {
					id
					displayProperties {
						name
						description
					}
					startStatus {
						displayProperties {
							name
						}
					}
					destinationStatus {
						displayProperties {
							name
						}
					}
				}
				workflow {
					id
				}
			}
		}
	}
`;

const WorkflowPanelBodyTransitionMutation = gql`
	mutation WorkflowPanelBodyTransitionMutation($input: ConfluenceExecuteWorkflowTransitionInput!) {
		confluence_executeWorkflowTransition(input: $input) {
			errors {
				message
			}
			workflowApplication {
				currentWorkflowStatus {
					displayProperties {
						name
					}
				}
			}
		}
	}
`;

const panelStyles = xcss({
	paddingInline: 'space.250',
});

const styles = xcss({
	backgroundColor: 'color.background.accent.purple.subtler',
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	borderRadius: '3px',
});

export const WorkflowIcon = () => <DataFlowIcon label="" />;

const PanelComponent = () => {
	const { userId, cloudId } = useSessionData();
	const [contentId] = usePageContentId();

	const pageAri = contentId
		? ConfluencePageAri.create({
				siteId: cloudId,
				pageId: contentId,
			}).toString()
		: undefined;

	const { data } = useQuery(WorkflowPanelBodyQuery, {
		skip: !pageAri,
		variables: {
			contentId: pageAri,
		},
	});

	const [applyTransition] = useMutation(WorkflowPanelBodyTransitionMutation, {
		refetchQueries: ['WorkflowPanelBodyQuery'],
	});

	const workflowStatus = data?.confluence_workflowApplicationsByContentId?.nodes?.[0];
	const isReviewRequested =
		workflowStatus?.currentWorkflowStatus?.displayProperties?.name === 'Review Requested';

	const handleTransitionWorkflow = (id: string) => {
		void applyTransition({
			variables: {
				input: {
					targetContentId: pageAri,
					transitionId: id,
					workflowId: workflowStatus.workflow.id,
				},
			},
		});
	};

	return (
		<Stack space="space.400" xcss={panelStyles}>
			{workflowStatus &&
				workflowStatus.availableTransitions.map((transition: any) => (
					<Stack space="space.100" key={transition.id}>
						<Heading size="xsmall">{transition.displayProperties.description}</Heading>
						<Button
							appearance="primary"
							onClick={() => {
								handleTransitionWorkflow(transition.id);
							}}
						>
							{transition.displayProperties.name}
						</Button>
					</Stack>
				))}
			{isReviewRequested ? (
				<Stack space="space.150">
					<Heading size="small">Approvers</Heading>
					<ApproverAvatar accountId={userId ?? undefined} />
					<ApproverAvatar accountId="557057:3a2d4c2a-db9b-4d67-880f-1b4c77e270e9" />
				</Stack>
			) : null}
			<Stack space="space.150">
				<Heading size="small">Properties</Heading>
				<Stack space="space.150">
					<Inline alignBlock="center" space="space.200" spread="space-between">
						<Inline alignBlock="center" space="space.200">
							<IssueIcon label="" />
							<Text>Status</Text>
						</Inline>
						{workflowStatus ? (
							<Box xcss={styles}>
								<Inline space="space.050" alignBlock="center">
									<PriorityTrivialIcon label="" color={token('color.icon.accent.purple')} />
									<Text weight="semibold">
										{workflowStatus.currentWorkflowStatus.displayProperties.name}
									</Text>
								</Inline>
							</Box>
						) : null}
					</Inline>
					<Inline alignBlock="center" space="space.200" spread="space-between">
						<Inline alignBlock="center" space="space.200">
							<ClockIcon label="" />
							<Text>Due date</Text>
						</Inline>
						<Text>Thu, Sep 12</Text>
					</Inline>
					<Inline alignBlock="center" space="space.200" spread="space-between">
						<Inline alignBlock="center" space="space.200">
							<PersonIcon label="" />
							<Text>Creator</Text>
						</Inline>
						<Link href="/wiki/workflows">Michael Scott</Link>
					</Inline>
				</Stack>
			</Stack>
			<Stack space="space.150">
				<Heading size="small">Workflow activities</Heading>
				<Textfield appearance="standard" label="Standard" placeholder="Write a comment" />
			</Stack>
		</Stack>
	);
};

export const WorkflowPanelBody = withErrorBoundary({
	attribution: Attribution.AUTOMATION_FOR_CONFLUENCE,
})(PanelComponent);

import gql from 'graphql-tag';

export const DetailsPanelRedactionsRowQuery = gql`
	query DetailsPanelRedactionsRowQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			redactionMetadataCount
		}
	}
`;

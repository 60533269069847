// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { ContentStateState, ContentState } from './types';
import type { EditorContentStateQuery as EditorContentStateQueryType } from './__types__/EditorContentStateQuery';

export const defaultValues: ContentStateState = {
	contentState: null,
	shouldRunContentStateEffect: true,
	defaultStatesArray: [],
	customStatesArray: [],
	isContentStateEnabled: true,
	isSuggestedEnabled: true,
	isCustomEnabled: true,
	isUpdating: false,
};
export class ContentStateStateContainer extends Container<ContentStateState> {
	state = { ...defaultValues };

	constructor(ssrContentStateData?: any) {
		super();

		if (ssrContentStateData) {
			this.populateState(ssrContentStateData as EditorContentStateQueryType);
		}
	}

	populateState(ssrContentStateData: EditorContentStateQueryType) {
		const newContentStateValues = Object.assign({}, defaultValues);
		newContentStateValues.shouldRunContentStateEffect = false;
		newContentStateValues.contentState = ssrContentStateData?.singleContent?.contentState || null;
		newContentStateValues.defaultStatesArray =
			ssrContentStateData?.availableContentStates?.spaceContentStates || [];
		newContentStateValues.customStatesArray =
			ssrContentStateData?.availableContentStates?.customContentStates || [];
		// If an admin has turned off a toggle, BE returns us the value as null
		// If an empty array is returned [], means the feature is still enabled - there's just no values in there.
		// if both return as null it means the admin disabled content states
		const isSuggestedContentStatesDisabled =
			!ssrContentStateData?.availableContentStates?.spaceContentStates;
		const isCustomContentStatesDisabled =
			!ssrContentStateData?.availableContentStates?.customContentStates;
		const isContentStatesDisabled =
			isSuggestedContentStatesDisabled && isCustomContentStatesDisabled;
		if (isContentStatesDisabled) {
			newContentStateValues.isContentStateEnabled = false;
		} else if (isSuggestedContentStatesDisabled) {
			// if spaceContentStates is null, admin disabled suggested
			newContentStateValues.isSuggestedEnabled = false;
		} else if (isCustomContentStatesDisabled) {
			// if customContentStates is null, admin disabled custom
			newContentStateValues.isCustomEnabled = false;
		}

		this.state = newContentStateValues;
	}

	setContentState = (contentState: ContentState | null) => this.setState({ contentState });
	setShouldRunContentStateEffect = (shouldRunContentStateEffect: boolean) =>
		this.setState({ shouldRunContentStateEffect });
	setDefaultStatesArray = (defaultStatesArray: (ContentState | null)[]) =>
		this.setState({ defaultStatesArray });
	setCustomStatesArray = (customStatesArray: (ContentState | null)[]) =>
		this.setState({ customStatesArray });
	setIsContentStateEnabled = (isContentStateEnabled: boolean) =>
		this.setState({ isContentStateEnabled });
	setIsSuggestedEnabled = (isSuggestedEnabled: boolean) => this.setState({ isSuggestedEnabled });
	setIsCustomEnabled = (isCustomEnabled: boolean) => this.setState({ isCustomEnabled });
	setIsUpdating = (isUpdating: boolean) => this.setState({ isUpdating });
	setInitialValues = (contentStateState: ContentStateState) => this.setState(contentStateState);
	resetToDefaults = () => this.setState({ ...defaultValues });
}

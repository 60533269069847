import React from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Inline } from '@atlaskit/primitives/compiled';
import { useAISmartButton } from '@atlassian/ai-smart-button-api';

// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { AIButton } from '../ai-button';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { useDynamicSurfacePanel } from '../ai-dynamic-surface/useDynamicSurfacePanel';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { AIProactiveNudge } from '../ai-proactive-nudge';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import type { ObjectSidebar } from '../common/types/object-sidebar-types';

export type AISmartButtonProps = {
	objectSidebar: ObjectSidebar;
};

export const AISmartButton = ({ objectSidebar }: AISmartButtonProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const dynamicSurfacePanel = useDynamicSurfacePanel(objectSidebar);
	const [{ surfaceMetadata }] = useAISmartButton();

	const onAISmartButtonClick = React.useCallback(() => {
		const { isObjectSidebarShown, panel } = objectSidebar.state;
		const { hideObjectSidebar, showObjectSidebar } = objectSidebar.actions;
		if (isObjectSidebarShown && panel?.id === dynamicSurfacePanel.id) {
			hideObjectSidebar();
		} else {
			showObjectSidebar(dynamicSurfacePanel, 'push');
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: surfaceMetadata.contentType,
				action: 'clicked',
				actionSubject: 'rovoButton',
				actionSubjectId: 'aiRovoSmartButton',
				attributes: {
					pageMode: surfaceMetadata.pageMode,
				},
			},
		}).fire();
	}, [dynamicSurfacePanel, objectSidebar, createAnalyticsEvent, surfaceMetadata]);

	return (
		<Inline space="space.100" alignBlock="center">
			<AIProactiveNudge />
			<AIButton onClick={onAISmartButtonClick} />
		</Inline>
	);
};

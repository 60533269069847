import React from 'react';

import DataFlowIcon from '@atlaskit/icon/core/data-flow';
import { IconButton } from '@atlaskit/button/new';

import { fg } from '@confluence/feature-gating';
import { useWorkflowsPanel } from '@confluence/advanced-workflows/entry-points/useWorkflowsPanel';

export const WorkflowsPanelButton = () => {
	const { showWorkflowsPanel } = useWorkflowsPanel();

	return (
		<IconButton
			icon={DataFlowIcon}
			appearance="subtle"
			shape={fg('ai-smart-button-team-25') ? 'default' : 'circle'}
			label=""
			testId="sidebar-workflows-button"
			onClick={showWorkflowsPanel}
			tooltip={{
				position: 'left',
			}}
			data-vc="object-sidebar-workflows-button"
		/>
	);
};

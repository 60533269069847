import { LoadableLazy } from '@confluence/loadable';

export const EditLabelDialogLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditLabelDialogComponent" */ './EditLabelDialogComponent'
			)
		).EditLabelDialogComponent,
});

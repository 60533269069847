import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Inline } from '@atlaskit/primitives';
import PersonAvatarIcon from '@atlaskit/icon/core/person-avatar';
import Skeleton from '@atlaskit/skeleton';
import Tooltip from '@atlaskit/tooltip';
import Link from '@atlaskit/link';

import { ProfileCardWrapper } from '@confluence/profile';
import { PEOPLE_DIRECTORY } from '@confluence/named-routes';

import { truncateName } from '../userUtils';
import { useContentCreator } from '../hooks/useContentCreator';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	creator: {
		id: 'details-panel.properties-section.creator-row',
		defaultMessage: 'Creator',
		description: "A title that accompanies the content creator's avatar and display name",
	},
	accessibleAvatarLabel: {
		id: 'details-panel.properties-section.creator-row.accessible-avatar-label',
		defaultMessage: '{displayName}’s profile picture',
		description: "Alt text and aria-label for a user's profile picture",
	},
});

const MAX_DISPLAY_NAME_LENGTH = 25;

export const CreatorRow = () => {
	const { formatMessage } = useIntl();
	const { data, error, refetch, loading } = useContentCreator();
	const { accountId, displayName, profilePicturePath } = data;
	const profileURL = accountId ? `${PEOPLE_DIRECTORY.toUrl()}/${accountId}` : '';

	const renderCreatorItem = () => {
		if (loading) {
			return <Skeleton width="80px" height="24px" borderRadius={3} testId="creator-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline alignBlock="center" alignInline="center" space="space.100">
				<ProfileCardWrapper userId={accountId}>
					<Avatar
						size="small"
						href={profileURL}
						src={profilePicturePath ?? undefined}
						name={formatMessage(i18n.accessibleAvatarLabel, {
							displayName,
						})}
					/>
				</ProfileCardWrapper>
				<Tooltip content={displayName}>
					{/* To be replaced by Link introduced in https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/163767/overview */}
					<Link appearance="subtle" href={profileURL}>
						{truncateName(displayName, MAX_DISPLAY_NAME_LENGTH)}
					</Link>
				</Tooltip>
			</Inline>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.creator)} icon={<PersonAvatarIcon label="" />}>
			{renderCreatorItem()}
		</DetailRow>
	);
};

import { defineMessages } from 'react-intl-next';

import { useObjectSidebar } from '@confluence/object-sidebar-api';

import { JiraWorkItemsPanelBody } from '../components/JiraWorkItemsPanelBody';
import { JiraWorkItemsPanelIcon } from '../components/JiraWorkItemsPanelIcon';
import type { JiraMetadata } from '../constants/jiraMetaData';
import { JiraWorkItemsHeaderNavigation } from '../components/JiraWorkItemsHeaderNavigation';

export const i18n = defineMessages({
	jiraWorkItemsPanelHeaderLabel: {
		id: 'details-panel.jira-work-items.header.label',
		defaultMessage: 'Jira work items',
		description: 'Label for the details panel header',
	},
});

export const useShowJiraWorkItemsPanel = (jiraMetaData: JiraMetadata) => {
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar }] = useObjectSidebar();
	const panelId = 'jiraWorkItemsPanel';
	const showJiraWorkItemsPanel = () => {
		showObjectSidebar(
			{
				id: panelId,
				headerComponentElements: {
					HeaderIcon: JiraWorkItemsPanelIcon,
					headerLabel: i18n.jiraWorkItemsPanelHeaderLabel,
					HeaderBeforeIconElement: JiraWorkItemsHeaderNavigation,
				},
				BodyComponent: () => JiraWorkItemsPanelBody(jiraMetaData),
			},
			'push',
		);
	};
	return {
		isJiraWorkItemsPanelShown: isObjectSidebarShown && panel?.id === panelId,
		showJiraWorkItemsPanel,
	};
};

import React from 'react';

import VideoIcon from '@atlaskit/icon/core/video';
import type { NewCoreIconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

export const LoomVideoIcon = (props: NewCoreIconProps) => {
	const color = props.color || token('color.icon.subtle');
	const label = props.label || '';
	return <VideoIcon {...props} color={color} label={label} spacing="spacious" />;
};

import type { ApolloClient } from 'apollo-client';
import { defineMessages, type IntlFormatters } from 'react-intl-next';

import type { OptionData, Promisable } from '@atlaskit/user-picker';

import { ExternalUserLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalUserLozengeProps';
import { ExternalGroupLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalGroupLozengeProps';
import { perfMarkStart, perfMarkEnd } from '@confluence/performance';

import type { SitePermissionTypeFilter } from './__types__/UserAndGroupSearchQuery';
import { SitePermissionType } from './__types__/UserAndGroupSearchQuery';
import type { UserGroupItemProps } from './UserAndGroupSearchPicker';
import { UserAndGroupSearchQuery } from './UserAndGroupSearchQuery.graphql';

const i18n = defineMessages({
	allLicensedUsers: {
		id: 'user-and-group-search.all-licensed-users',
		defaultMessage: 'All Confluence users',
		description: 'Display for a set of users that appear within a list of suggestions',
	},
	allProductAdmins: {
		id: 'user-and-group-search.all-product-admins',
		defaultMessage: 'All Confluence admins',
		description: 'Display for a set of administrators that appear within a list of suggestions',
	},
});

type userGroupSearchArgs = {
	searchTerm: string;
	client: ApolloClient<any>;
	includeGroup: boolean;
	includeAccessClassSuggestions?: boolean;
	setError: (error: Error) => void;
	sitePermissionTypeFilter: SitePermissionTypeFilter;
	filterResults?: (value: UserGroupItemProps) => boolean;
	withUsers?: boolean;
	formatMessage: IntlFormatters['formatMessage'];
};

export const userGroupSearchPromise = ({
	searchTerm,
	client,
	includeGroup,
	includeAccessClassSuggestions,
	setError,
	sitePermissionTypeFilter,
	filterResults,
	withUsers,
	formatMessage,
}: userGroupSearchArgs): Promisable<OptionData[]> => {
	const subject = 'userAndGroupSearchQuery';
	perfMarkStart({
		subject,
	});
	return client
		.query({
			query: UserAndGroupSearchQuery,
			variables: {
				withGroups: includeGroup,
				searchTerm,
				sitePermissionTypeFilter,
				withUsers,
			},
		})
		.then(({ data }) => {
			const { users = [], groups = [] } = data.userGroupSearch;
			const userList: UserGroupItemProps[] = users.map((item) => ({
				id: `user-${item.accountId}`,
				name: item.displayName,
				extra: item,
				type: 'user',
				avatarUrl: item.profilePicture ? item.profilePicture.path : '',
				lozenge:
					item.permissionType === SitePermissionType.EXTERNAL ? ExternalUserLozengeProps : null,
			}));
			const groupList: UserGroupItemProps[] = groups.map((item) => ({
				id: `group-${item.id}`,
				name: item.name,
				type: 'group',
				extra: item,
				lozenge:
					item.permissionType === SitePermissionType.EXTERNAL ? ExternalGroupLozengeProps : null,
			}));
			const accessClassList: UserGroupItemProps[] = [];
			if (includeAccessClassSuggestions) {
				const cleanedSearchTerm = searchTerm.replace(/\s/g, '').toLowerCase();
				if ('allconfluenceusers'.startsWith(cleanedSearchTerm)) {
					accessClassList.push({
						id: 'all-licensed-users',
						name: formatMessage(i18n.allLicensedUsers),
						type: 'custom',
						avatarUrl: require('./images/atlassian-icon.svg'),
					});
				}
				if ('allconfluenceadmins'.startsWith(cleanedSearchTerm)) {
					accessClassList.push({
						id: 'all-product-admins',
						name: formatMessage(i18n.allProductAdmins),
						type: 'custom',
						avatarUrl: require('./images/atlassian-icon.svg'),
					});
				}
			}
			const usersGroupsAndAccessClasses = [...accessClassList, ...userList, ...groupList];
			perfMarkEnd({
				subject,
			});
			return filterResults
				? usersGroupsAndAccessClasses.filter(filterResults)
				: usersGroupsAndAccessClasses;
		})
		.catch((error) => {
			setError(error);
			return [];
		});
};

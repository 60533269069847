import React from 'react';

import {
	ClassificationModalContextProvider,
	ContentTypeClassification,
	type ContentClassificationMetadata,
} from '@confluence/data-classification';
import { usePageContentId, useContentType, usePageSpaceKey } from '@confluence/page-context';
import { OperationTypes, useCanPerformContentOperation } from '@confluence/entity-operations';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';
import {
	CURRENT_STATUS,
	DRAFT_STATUS,
	ContentStatus,
} from '@confluence/page-utils/entry-points/enums';

import { useIsDisconnected } from '../hooks/useIsDisconnected';

import { AddClassification } from './AddClassification';

export type ContentClassifierProps = {
	contentClassificationMetadata: ContentClassificationMetadata;
};

export const ContentClassifier = ({ contentClassificationMetadata }: ContentClassifierProps) => {
	const isDisconnected = useIsDisconnected();
	const [contentType] = useContentType();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const { contentStatus } = useContentStatus();

	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType,
		operation: OperationTypes.UPDATE,
		status: contentStatus === ContentStatus.DRAFT ? DRAFT_STATUS : CURRENT_STATUS,
	});

	const hasEditPermission = canUpdateContent ?? false;

	return contentClassificationMetadata.classification ? (
		<ContentTypeClassification
			spaceKey={spaceKey ?? ''}
			contentId={contentId ?? ''}
			userOffline={isDisconnected}
			readOnly={!hasEditPermission}
			contentType={contentType ?? ''}
			contentClassificationMetadata={contentClassificationMetadata}
			popupPlacementOverride="bottom-end"
		/>
	) : (
		<ClassificationModalContextProvider>
			<AddClassification
				contentClassificationMetadata={contentClassificationMetadata}
				hasEditPermission={hasEditPermission}
			/>
		</ClassificationModalContextProvider>
	);
};

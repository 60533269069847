import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import MinusSquareIcon from '@atlaskit/icon/core/minus-square';

import { RedactionsDialogLoader } from '@confluence/redactions/entry-points/dialog';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { usePageContentId } from '@confluence/page-context';

import { useContentRedactions } from '../hooks/useContentRedactions';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	redactionsSection: {
		id: 'details-panel.properties-section.redaction-row',
		defaultMessage: 'Redactions',
		description: 'A title that appears next a count of content redactions',
	},
});

export const RedactionsRow = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [contentId = ''] = usePageContentId();
	const { showModal } = useDialogs();

	const { redactionMetadataCount, error, refetch } = useContentRedactions();

	const handleRedactionsItemClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'redactionsDetailsPanel',
				source: 'contentWrapper',
			},
		}).fire();
		showModal(RedactionsDialogLoader, { contentId });
	}, [createAnalyticsEvent, showModal, contentId]);

	if (!redactionMetadataCount && !error) {
		return null;
	}

	const renderRedactionsItem = () => {
		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<SubtleLink
				onClick={handleRedactionsItemClick}
				onClickDisabled={!contentId}
			>{`${redactionMetadataCount}`}</SubtleLink>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.redactionsSection)} icon={<MinusSquareIcon label="" />}>
			{renderRedactionsItem()}
		</DetailRow>
	);
};

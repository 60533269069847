import React, { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import IssuesIcon from '@atlaskit/icon/core/issues';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { useShowJiraWorkItemsPanel } from '../hooks/useShowJiraWorkItemsPanel';
import { useGetJiraMetadata } from '../hooks/useGetJiraMetadata';

import { DetailRow } from './DetailRow';
import { SubtleLink } from './SubtleLink';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	jiraWorkItems: {
		id: 'details-panel.links-section.jira-work-items-row',
		defaultMessage: 'Jira work items',
		description:
			'Text that appears next to a number indicating the number of Jira work items on specific content (multiple)',
	},
	jiraWorkItem: {
		id: 'details-panel.links-section.jira-work-item-row',
		defaultMessage: 'Jira work item',
		description:
			'Text that appears next to a number indicating the number of Jira work items on specific content (singular)',
	},
});

export const JiraWorkItemsRow = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, isLoading, error, refetch } = useGetJiraMetadata();
	const { showJiraWorkItemsPanel } = useShowJiraWorkItemsPanel(data!);
	const isLivePage = useIsCurrentPageLive();
	const isOnEditRoute = useIsEditorPage();

	const shouldRenderDetailRow = useMemo(
		() => !isLivePage && !isOnEditRoute && !isLoading && ((data && data.count > 0) || error),
		[isLivePage, isOnEditRoute, isLoading, data, error],
	);

	const createWorkItemAnalytics = useCallback(
		(eventType: 'sendUIEvent' | 'sendTrackEvent', action: 'clicked' | 'shown') => {
			createAnalyticsEvent({
				type: eventType,
				data: {
					action,
					actionSubject: 'jiraWorkItemsRow',
					source: 'linkedJiraWorkItems',
					attributes: {
						linkedJiraWorkItemCount: data?.count,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, data],
	);

	const handleClickJiraWorkItems = useCallback(() => {
		createWorkItemAnalytics('sendUIEvent', 'clicked');
		showJiraWorkItemsPanel();
	}, [showJiraWorkItemsPanel, createWorkItemAnalytics]);

	useEffect(() => {
		if (shouldRenderDetailRow && !error) {
			createWorkItemAnalytics('sendTrackEvent', 'shown');
		}
	}, [shouldRenderDetailRow, error, createWorkItemAnalytics]);

	return shouldRenderDetailRow ? (
		<DetailRow
			itemName={formatMessage(data && data.count > 1 ? i18n.jiraWorkItems : i18n.jiraWorkItem)}
			icon={<IssuesIcon label="" />}
		>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<SubtleLink onClick={handleClickJiraWorkItems}>{data?.count}</SubtleLink>
			)}
		</DetailRow>
	) : null;
};

import type {
	DetailsPanelContentContributorsQuery_contentContributors_nodes as Nodes,
	DetailsPanelContentContributorsQuery_contentContributors_nodes_KnownUser as KnownUser,
	DetailsPanelContentContributorsQuery_contentContributors_nodes_User as User,
} from './queries/__types__/DetailsPanelContentContributorsQuery';

export const hasAccountId = (ownedBy?: Nodes | null): ownedBy is KnownUser | User =>
	!!ownedBy && 'accountId' in ownedBy;

//TODO Move this logic to @confluence/profile once this PR is merged and we update the component in CreatorRow: https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/163767/overview
export const truncateName = (name: string, maxChar: number) => {
	if (name.length <= maxChar) {
		return name;
	}

	return `${name.slice(0, maxChar)}...`;
};

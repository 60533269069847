import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { isConfluenceRedirectToLoomSDKExperimentEnabled } from '@confluence/growth-experiment-helpers';

export { useLoomInsertUrlState } from './useLoomInsertUrlState';

import { LoomPageHeaderPlaceholder } from './LoomPageHeaderPlaceholder';

export const PageHeaderLoomEntryPoint = LoadableAfterPaint({
	loader: async () => {
		const { isVariantCohort } = isConfluenceRedirectToLoomSDKExperimentEnabled(); // evaluate experiment here but does NOT fire exposure event
		return isVariantCohort
			? (
					await import(
						/* webpackChunkName: "loadable-PageHeaderLoomEntryPoint" */ './Experiment_RedirectToSdkAfterCrossflow/PageHeaderLoomEntryPoint'
					)
				).PageHeaderLoomEntryPoint
			: (
					await import(
						/* webpackChunkName: "loadable-PageHeaderLoomEntryPoint" */ './PageHeaderLoomEntryPoint'
					)
				).PageHeaderLoomEntryPoint;
	},
	loading: ({ isCircle }) => <LoomPageHeaderPlaceholder isCircle={isCircle} />,
});

import { cfetch } from '@confluence/network';

type ErrorDetail = {
	status: number;
	code: string;
	title: string;
	detail: string | null;
};

type ErrorResponse = {
	errors: ErrorDetail[];
};

type Version = {
	number: number;
	message: string;
	minorEdit: boolean;
	authorId: string;
	createdAt: string;
};

type Links = {
	webui: string;
};

type Result = {
	id: string;
	type: string;
	status: string;
	title: string;
	createdAt: string;
	authorId: string;
	spaceId: string;
	pageId: string;
	body: Record<string, unknown>; // Assuming the body is an object with unspecified properties
	version: Version;
	_links: Links;
};

type SuccessResponse = {
	results: Result[];
	_links: {
		base: string;
	};
};

type ApiResponse = SuccessResponse | ErrorResponse;

export const getCustomContent = async (
	contentId: string,
	contentType: string | null | undefined,
): Promise<ApiResponse> => {
	try {
		// v2 APIs pluralise all the content types... and if we don't get a type, default to pages
		const contentTypeForApi = contentType ? `${contentType}s` : 'pages';

		const response = await cfetch(`/wiki/api/v2/${contentTypeForApi}/${contentId}/custom-content`);

		return response.json();
	} catch (error) {
		return {
			errors: [{ status: 500, code: 'unknown', title: 'Error', detail: error.message }],
		};
	}
};

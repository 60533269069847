import { fg } from '@confluence/feature-gating';
import { query } from '@confluence/query-preloader-tools';

import { DetailsPanelRedactionsRowQuery } from './queries/DetailsPanelRedactionsRowQuery.graphql';

interface PreloadRedactionsQueriesI {
	contentId: string;
	canUpdateContent: boolean;
}

export const preloadRedactionsQueries = async ({
	contentId,
	canUpdateContent,
}: PreloadRedactionsQueriesI) => {
	if (!contentId || !canUpdateContent || !fg('dlp_cc-redactions-metadata-modal')) {
		return;
	}

	return query({
		query: DetailsPanelRedactionsRowQuery,
		variables: { contentId },
		errorPolicy: 'all',
	});
};

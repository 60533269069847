import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';

import Avatar from '@atlaskit/avatar/Avatar';
import { Inline, Text, Stack } from '@atlaskit/primitives';

const ApproverAvatarQuery = gql`
	query ApproverAvatarQuery($id: ID!) {
		user(id: $id) {
			id
			displayName
			photos {
				value
			}
		}
	}
`;

type Props = {
	accountId?: string;
};

export const ApproverAvatar = ({ accountId }: Props) => {
	const { data } = useQuery(ApproverAvatarQuery, {
		skip: !accountId,
		variables: {
			id: accountId,
		},
	});

	const avatarUrl = data?.user?.photos?.[0].value;
	const displayName = data?.user?.displayName || 'Anonymous';

	return (
		<Inline alignBlock="center" space="space.100">
			<Avatar size="medium" src={avatarUrl} />
			<Stack>
				<Text>{displayName}</Text>
				<Text size="small" color="color.text.subtlest">
					Pending
				</Text>
			</Stack>
		</Inline>
	);
};

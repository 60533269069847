import React from 'react';

import { xcss, Box } from '@atlaskit/primitives';

import { OwnerSection } from './OwnerSection';

const userSectionStyles = xcss({
	paddingTop: 'space.100',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
});

export const UserSection = () => (
	<Box xcss={userSectionStyles}>
		<OwnerSection />
	</Box>
);

import { useState, useEffect } from 'react';

import { useNativeCollabState } from '@confluence/native-collab';

export const useIsDisconnected = () => {
	const [isDisconnected, setIsDisconnected] = useState(false);
	const [{ collabEditProvider }] = useNativeCollabState();

	const handleDisconnected = () => {
		setIsDisconnected(true);
	};

	const handleConnected = () => {
		setIsDisconnected(false);
	};

	useEffect(() => {
		if (!collabEditProvider) return;

		collabEditProvider.on('connected', handleConnected);
		collabEditProvider.on('disconnected', handleDisconnected);

		return () => {
			collabEditProvider.off('connected', handleConnected);
			collabEditProvider.off('disconnected', handleDisconnected);
		};
	});

	return isDisconnected;
};

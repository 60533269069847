import React, { type FC } from 'react';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';

import { GeneratingStreamAnimation } from '../../common/assets/GeneratingStreamAnimation';

const BoxStyles = xcss({
	color: 'color.text.subtlest',
	backgroundColor: 'elevation.surface.sunken',
	padding: 'space.150',
	borderRadius: '8px',
	borderBottomLeftRadius: '3px',
});

const i18n = defineMessages({
	generatingSummaryText: {
		id: 'ai-summary-loading.title',
		defaultMessage: 'Writing a summary',
		description: 'Text for loading state of ai summary streaming',
	},
	generatingSummaryAnimationAlt: {
		id: 'ai-stream-loading.generation-animation',
		defaultMessage: 'Generating',
		description: 'Alt text of the generation animation',
	},
});

export const AISummaryLoading: FC = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={BoxStyles}>
			<Stack space="space.100">
				<FormattedMessage {...i18n.generatingSummaryText} />
				<GeneratingStreamAnimation alt={formatMessage(i18n.generatingSummaryAnimationAlt)} />
			</Stack>
		</Box>
	);
};

import type { DetailsPanelSingleContentStateQuery } from '../queries/__types__/DetailsPanelSingleContentStateQuery';

export const normalizeContentStatusData = (data?: DetailsPanelSingleContentStateQuery) => {
	const singleContentState = data?.singleContent?.contentState;

	return {
		color: singleContentState?.color ?? '',
		restrictionLevel: singleContentState?.restrictionLevel,
		name: singleContentState?.name ?? '',
	};
};

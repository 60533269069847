import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import { Card } from '@atlaskit/smart-card';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';

import { ConfluenceSmartCardProvider } from '@confluence/smart-card-provider/entry-points/ConfluenceSmartCardProvider';

import type { JiraMetadata } from '../constants/jiraMetaData';

export const i18n = defineMessages({
	viewMoreInJiraLinkText: {
		id: 'details-panel.links-section.jira-work-items-panel-body.view-more-link-text',
		defaultMessage: 'View {count} more in System Jira',
		description: 'Link text to view remaining work items in Jira',
	},
	workItemsCount: {
		id: 'details-panel.links-section.jira-work-items-panel-body.work-items-count',
		defaultMessage: '{count, plural, one {# work item} other {# work items}}',
		description: 'Header which displays the total number of work items',
	},
});

const panelBodyStyles = xcss({
	paddingInline: 'space.400',
});

export const JiraWorkItemsPanelBody = (jiraMetadata: JiraMetadata) => {
	const { formatMessage } = useIntl();

	const jiraWorkItemsMetadata = jiraMetadata.groups.find((group) => group.type === 'ISSUES');
	const workItemsJQLSearchUrl = jiraWorkItemsMetadata?.links.find(
		(link) => link.appName === 'System Jira',
	)?.url;
	const totalJiraLinksCount = jiraMetadata.count;
	const workItems = jiraWorkItemsMetadata?.items;

	const openInNewTab = (issueUrl: string) => {
		window.open(issueUrl, '_blank', 'noopener,noreferrer');
	};

	if (!workItems || !totalJiraLinksCount) {
		return null;
	}

	const context = { source: 'linkedJiraWorkItems' };

	return (
		<ConfluenceSmartCardProvider>
			<Stack
				xcss={panelBodyStyles}
				alignBlock="start"
				space="space.200"
				testId="jira-work-items-panel-body"
			>
				<Box testId="jira-work-items-panel-heading">
					<Heading size="small">
						{formatMessage(i18n.workItemsCount, { count: totalJiraLinksCount })}
					</Heading>
				</Box>
				{workItems.map((issue, index) => (
					<Box testId="smartlink-item-wrapper" key={index}>
						<AnalyticsContext data={context}>
							<Card url={issue.url} appearance="inline" onClick={() => openInNewTab(issue.url)} />
						</AnalyticsContext>
					</Box>
				))}
				<Box testId="jira-work-items-panel-jql-link">
					{workItemsJQLSearchUrl && (
						<Link target="_blank" href={workItemsJQLSearchUrl}>
							{formatMessage(i18n.viewMoreInJiraLinkText, {
								count: jiraMetadata.count - workItems.length,
							})}
						</Link>
					)}
				</Box>
			</Stack>
		</ConfluenceSmartCardProvider>
	);
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AddIcon from '@atlaskit/icon/core/add';
import { IconButton } from '@atlaskit/button/new';

import {
	useClassificationModalDispatchContext,
	ClassificationEditModal,
	type ContentClassificationMetadata,
} from '@confluence/data-classification';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';

const i18n = defineMessages({
	addClassificationLabel: {
		id: 'details-panel.activity-section.classification-row.add-classification-label',
		defaultMessage: 'Add classification',
		description:
			'aria-label for button to add classification (highly confidential, confidential, internal, public) to content',
	},
});

export type AddClassificationProps = {
	contentClassificationMetadata: ContentClassificationMetadata;
	hasEditPermission: boolean;
};

export const AddClassification = ({
	contentClassificationMetadata,
	hasEditPermission,
}: AddClassificationProps) => {
	const { formatMessage } = useIntl();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();

	const { setIsClassificationModalOpen } = useClassificationModalDispatchContext();

	return (
		<>
			<IconButton
				appearance="subtle"
				icon={AddIcon}
				label={formatMessage(i18n.addClassificationLabel)}
				onClick={() => setIsClassificationModalOpen(true)}
				isDisabled={!hasEditPermission}
			/>
			<ClassificationEditModal
				contentId={contentId ?? ''}
				spaceKey={spaceKey ?? ''}
				contentClassificationMetadata={contentClassificationMetadata}
			/>
		</>
	);
};

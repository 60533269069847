import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Skeleton from '@atlaskit/skeleton';
import AttachmentIcon from '@atlaskit/icon/core/attachment';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { usePageContentId } from '@confluence/page-context';

import { useContentAttachments } from '../hooks/useContentAttachments';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	files: {
		id: 'details-panel.links-section.files-row',
		defaultMessage: 'Files',
		description:
			'Text that appears next to a number indicating the number of attachments on specific content',
	},
});

export const FilesRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();
	const { data, loading, error, refetch } = useContentAttachments();
	const { attachmentsCount } = data;

	const renderFilesContent = () => {
		if (loading) {
			return <Skeleton width="48px" height="24px" borderRadius={3} testId="files-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<SubtleLink href={`${CONTEXT_PATH}/pages/viewpageattachments.action?pageId=${contentId}`}>
				{attachmentsCount}
			</SubtleLink>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.files)} icon={<AttachmentIcon label="" />}>
			{renderFilesContent()}
		</DetailRow>
	);
};

import { LoadableLazy } from '@confluence/loadable';

export const RedactionsDialogLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RedactionsDialogComponent" */ './RedactionsDialog'
			)
		).RedactionsDialog,
});

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import EyeOpenIcon from '@atlaskit/icon/core/eye-open';
import Skeleton from '@atlaskit/skeleton';

import { useSessionData, ConfluenceEdition } from '@confluence/session-data';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { PAGE_ANALYTICS } from '@confluence/named-routes';

import { useContentViews } from '../hooks/useContentViews';

import { DetailRow } from './DetailRow';
import { SubtleLink } from './SubtleLink';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	viewsSection: {
		id: 'details-panel.activity-section.views-row',
		defaultMessage: 'Views',
		description:
			'Text that appears next to a number indicating the total views on specific content',
	},
});

const ViewsRow = () => {
	const { formatMessage } = useIntl();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { data, error, refetch, loading, shouldHideViewsAnalytics } = useContentViews();
	const { count } = data;

	const renderViewsItem = () => {
		if (loading) {
			return <Skeleton width="48px" height="24px" borderRadius={3} testId="views-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return <SubtleLink href={PAGE_ANALYTICS.toUrl({ spaceKey, contentId })}>{count}</SubtleLink>;
	};

	return !shouldHideViewsAnalytics ? (
		<DetailRow itemName={formatMessage(i18n.viewsSection)} icon={<EyeOpenIcon label="" />}>
			{renderViewsItem()}
		</DetailRow>
	) : null;
};

export const ViewsRowWrapper = () => {
	const { edition } = useSessionData();
	const isNotFreeEdition = edition !== ConfluenceEdition.FREE;

	return isNotFreeEdition ? <ViewsRow /> : null;
};

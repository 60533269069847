import React from 'react';

import { AISmartButton } from '@atlassian/ai-smart-button';
import { useAISmartButtonActions } from '@atlassian/ai-smart-button-api';
import { isFedRamp } from '@atlassian/atl-context';

import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';
import { useSessionData } from '@confluence/session-data';

import { useCreateObjectSidebarProp } from '../hooks/useCreateObjectSidebarProp';

type AISmartButtonProps = {
	contentId: string;
	contentType: string;
	pageMode: string;
};

export function AISmartButtonWrapper({ contentId, contentType, pageMode }: AISmartButtonProps) {
	const { cloudId } = useSessionData();
	const { setSurfaceMetadata } = useAISmartButtonActions();
	const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
		contentId,
	});

	const isEnabled = !isFedRamp() && isAIEnabledForContent && isRovoEnabledForContent;

	React.useEffect(() => {
		setSurfaceMetadata({
			siteId: cloudId,
			product: 'confluence',
			contentType,
			pageMode,
		});
	}, [cloudId, setSurfaceMetadata, contentType, pageMode]);

	const objectSidebar = useCreateObjectSidebarProp();

	if (!isEnabled) {
		return null;
	}

	return <AISmartButton objectSidebar={objectSidebar} />;
}

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AppIcon from '@atlaskit/icon/core/app';

import { CUSTOM_CONTENTS_ON_PAGE_LIST } from '@confluence/named-routes';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';

import { useCustomContent } from '../hooks/useCustomContent';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	customContent: {
		id: 'details-panel.links-section.custom-content-row',
		defaultMessage: 'Custom content',
		description:
			'Text that appears next to a number indicating the number of linked "custom contents" on a specific page',
	},
});

export const CustomContentRow = () => {
	const { formatMessage } = useIntl();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();

	const { data, error, refetch } = useCustomContent();
	const { customContentCount } = data;

	if (!customContentCount && !error) {
		return null;
	}

	return (
		<DetailRow itemName={formatMessage(i18n.customContent)} icon={<AppIcon label="" />}>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<SubtleLink
					href={CUSTOM_CONTENTS_ON_PAGE_LIST.toUrl({
						contentId,
						spaceKey,
					})}
				>
					{customContentCount}
				</SubtleLink>
			)}
		</DetailRow>
	);
};

import type { DetailsPanelContentViewsQuery } from '../queries/__types__/DetailsPanelContentViewsQuery';
import { SitePermissionType } from '../queries/__types__/DetailsPanelContentViewsQuery';

export const normalizeContentViewsData = (data?: DetailsPanelContentViewsQuery) => {
	const contentAnalyticsViewers = data?.contentAnalyticsViewers;
	const user = data?.user;

	return {
		count: contentAnalyticsViewers?.count,
		isInternalUser: user?.confluence?.permissionType === SitePermissionType.INTERNAL,
		isSSRError: contentAnalyticsViewers === null,
	};
};

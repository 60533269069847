/* eslint-disable check-file/filename-blocklist */
import { B200, G200, Y200, R200, P200 } from '@atlaskit/theme/colors';

import { PRIMARY_COLORS, COLOR_NAMES } from './colors';

// BE requires the color passed to it in hex but new tokens makes the color as a var(). This function helps translate between
// the color representations.
export const tokenToHex = (color: string | null) => {
	switch (color) {
		case PRIMARY_COLORS.BLUE:
			return B200;
		case PRIMARY_COLORS.GREEN:
			return G200;
		case PRIMARY_COLORS.YELLOW:
			return Y200;
		case PRIMARY_COLORS.PURPLE:
			return P200;
		case PRIMARY_COLORS.RED:
			return R200;
		default:
			return B200;
	}
};

export const hexToToken = (color: string) => {
	const upperCaseColor = color?.toUpperCase(); // need toUpper since color we get from BE is lowercase
	switch (upperCaseColor) {
		case B200:
			return PRIMARY_COLORS.BLUE;
		case G200:
			return PRIMARY_COLORS.GREEN;
		case Y200:
			return PRIMARY_COLORS.YELLOW;
		case P200:
			return PRIMARY_COLORS.PURPLE;
		case R200:
			return PRIMARY_COLORS.RED;
		default:
			return color;
	}
};

export function colorCodeToColorName(colorCode: string | null | undefined) {
	const upperCaseColorCode = colorCode?.toUpperCase();
	switch (upperCaseColorCode) {
		case PRIMARY_COLORS.BLUE.toUpperCase():
			return COLOR_NAMES.BLUE;
		case PRIMARY_COLORS.GREEN.toUpperCase():
			return COLOR_NAMES.GREEN;
		case PRIMARY_COLORS.YELLOW.toUpperCase():
			return COLOR_NAMES.YELLOW;
		case PRIMARY_COLORS.RED.toUpperCase():
			return COLOR_NAMES.RED;
		case PRIMARY_COLORS.PURPLE.toUpperCase():
			return COLOR_NAMES.PURPLE;
		default:
			return COLOR_NAMES.BLUE;
	}
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DetailsPanelContentViewsQuery
// ====================================================

export interface DetailsPanelContentViewsQuery_contentAnalyticsViewers {
  count: number;
}

export interface DetailsPanelContentViewsQuery_user_confluence {
  permissionType: SitePermissionType | null;
}

export interface DetailsPanelContentViewsQuery_user {
  id: string | null;
  confluence: DetailsPanelContentViewsQuery_user_confluence | null;
}

export interface DetailsPanelContentViewsQuery {
  contentAnalyticsViewers: DetailsPanelContentViewsQuery_contentAnalyticsViewers | null;
  user: DetailsPanelContentViewsQuery_user | null;
}

export interface DetailsPanelContentViewsQueryVariables {
  contentId: string;
  isDraft: boolean;
}

import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import EpicIcon from '@atlaskit/icon/core/epic';
import BookWithBookmarkIcon from '@atlaskit/icon/core/book-with-bookmark';
import InformationIcon from '@atlaskit/icon/utility/information';
import Tooltip from '@atlaskit/tooltip';

import { usePageContentId, usePageSpaceKey, useContentType } from '@confluence/page-context';
import { useSpaceId } from '@confluence/space-utils';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { useSwitchToLivePage } from '@confluence/live-pages-features/entry-points/useSwitchToLivePage';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { ShortcutVisualizer, getShortcutString, EDITOR2_SHORTCUTS } from '@confluence/shortcuts';
import { TOOLS_MENU_CONTAINER, useWebItemLocation } from '@confluence/web-item-location';
import { useCanPerformContentOperation, OperationTypes } from '@confluence/entity-operations';

import { useDetailsPanelEditorState } from '../useDetailsPanelEditorState';

import { DetailRow } from './DetailRow';
import { SubtleLink } from './SubtleLink';

const i18n = defineMessages({
	pageModeSectionTitle: {
		id: 'details-panel.page-mode-section.title',
		defaultMessage: 'Page mode',
		description: "Title for section containing the page's mode (whether it is a live page or not)",
	},
	livePageDescription: {
		id: 'details-panel.page-mode-section.live-page-description',
		defaultMessage: 'Live page',
		description: 'Text that describes the mode of a page that is live',
	},
	publishedPageDescription: {
		id: 'details-panel.page-mode-section.published-page-description',
		defaultMessage: 'Published',
		description:
			'Text that describes the mode of a page that is published, not live.  (It has separate published and draft versions.)',
	},
	publishButtonText: {
		id: 'details-panel.page-mode-section.publish-button.text',
		defaultMessage: 'Publish',
		description:
			'Text for a button that will save the current contents of this live page and convert it to a `published` format, with separate versions for viewing and editing.',
	},
	switchToLiveButtonText: {
		id: 'details-panel.page-mode-section.switch-button.text',
		defaultMessage: 'Switch to live edit',
		description:
			'Text for a button that will convert the current content of a published page into a live page, where there is one version for both viewing and editing.',
	},
});

const pageModeSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

const itemsStyles = xcss({
	paddingLeft: 'space.050',
});

const liveSettings = {
	descriptionI18n: i18n.livePageDescription,
	// TODO MODES-5737 Rick has said we will need to switch this to a different icon, since the "lightning bolt" EpicIcon is reserved for Jira Epics.
	icon: EpicIcon,
	buttonI18n: i18n.publishButtonText,
	tooltipContent: <ShortcutVisualizer shortcut={getShortcutString(EDITOR2_SHORTCUTS.PUBLISH)} />,
};
const publishedSettings = {
	descriptionI18n: i18n.publishedPageDescription,
	icon: BookWithBookmarkIcon,
	buttonI18n: i18n.switchToLiveButtonText,
	tooltipContent: undefined,
};

const SWITCH_TO_LIVE_EDIT_WEB_ITEM = 'action-switch-to-live-edit';

export const PageModeSection: React.FC = () => {
	const { formatMessage } = useIntl();
	const isLivePage = useIsCurrentPageLive();
	const settings = isLivePage ? liveSettings : publishedSettings;
	const Icon = settings.icon;

	const [contentType] = useContentType();
	const [spaceKey] = usePageSpaceKey();
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();
	const shouldShowPageModeSection =
		contentType === 'page' && !isCompanyHub && isLivePagesFeatureEnabled;

	const isOnEditRoute = useIsEditorPage();
	const { switchToLivePage: switchToLivePageInEditor, validatePageAndOpenModal } =
		useDetailsPanelEditorState();
	const [contentId] = usePageContentId();
	const spaceId = useSpaceId();
	// @ts-ignore This code will be deleted soon, so I am not going to connect flags up here.
	const switchToLivePageInViewScreen = useSwitchToLivePage({
		contentId: contentId!,
		spaceId,
		// This function is only used on the view page, so isUnpublishedDraft must be false.
		isUnpublishedDraft: false,
		analyticsSource: 'detailsPanel',
		adfOrigin: 'RENDERER',
		retrieveEditorTitleAndContent: undefined,
	});
	const switchToLivePage = isOnEditRoute ? switchToLivePageInEditor : switchToLivePageInViewScreen;
	const publishPage = validatePageAndOpenModal?.('DetailsPanelPublishButton');
	const convertFunc = isLivePage ? publishPage : switchToLivePage;

	// Only show the publish button if the user has permission to update (edit) this content.
	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType,
		operation: OperationTypes.UPDATE,
	});
	const shouldShowPublishButton = canUpdateContent;
	const { webItems } = useWebItemLocation({
		location: [TOOLS_MENU_CONTAINER],
		contentId,
	});
	// The BE handles the permissions checks for the "Switch to Live Edit" button,
	// and conditionally returns it as a webItem if the user is allowed to convert this content.
	const shouldShowSwitchToLiveButton = webItems?.some(
		(item) => item?.id === SWITCH_TO_LIVE_EDIT_WEB_ITEM,
	);

	const shouldShowButton = isLivePage ? shouldShowPublishButton : shouldShowSwitchToLiveButton;

	if (!shouldShowPageModeSection) {
		return null;
	}

	return (
		<Stack testId="page-mode-section" xcss={pageModeSectionStyles}>
			<FormattedMessage {...i18n.pageModeSectionTitle} tagName="h4" />
			<Stack xcss={itemsStyles}>
				<DetailRow itemName={formatMessage(settings.descriptionI18n)} icon={<Icon label="" />}>
					{shouldShowButton && (
						<Inline space="space.050" alignBlock="center">
							{settings.tooltipContent && (
								<Tooltip position="bottom" content={settings.tooltipContent}>
									<InformationIcon label="" color={token('color.link')} />
								</Tooltip>
							)}
							<SubtleLink onClick={convertFunc}>{formatMessage(settings.buttonI18n)}</SubtleLink>
						</Inline>
					)}
				</DetailRow>
			</Stack>
		</Stack>
	);
};

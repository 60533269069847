import type { DetailsPanelContentOwnerQuery } from '../queries/__types__/DetailsPanelContentOwnerQuery';
import { hasAccountId } from '../userUtils';

export const normalizeContentOwnerData = (data?: DetailsPanelContentOwnerQuery) => {
	const ownedBy = data?.content?.nodes?.[0]?.history?.ownedBy;

	return {
		accountId: hasAccountId(ownedBy) ? ownedBy.accountId : '',
		displayName: ownedBy?.displayName ?? '',
		profilePicturePath: ownedBy?.profilePicture?.path,
	};
};

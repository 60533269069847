import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import ClockIcon from '@atlaskit/icon/core/clock';
import Skeleton from '@atlaskit/skeleton';

import { useContentCreatedDate } from '../hooks/useContentCreatedDate';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	created: {
		id: 'details-panel.properties-section.created-row',
		defaultMessage: 'Created',
		description: 'A title that accompanies the date, indicating when the content was created',
	},
});

export const CreatedRow = () => {
	const { formatMessage, formatDate } = useIntl();
	const { data, error, refetch, loading } = useContentCreatedDate();
	const { date } = data;

	const renderCreatedItem = () => {
		if (loading) {
			return <Skeleton width="80px" height="24px" borderRadius={3} testId="created-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Text>
				{formatDate(new Date(date ?? ''), {
					year: 'numeric',
					month: 'long',
					day: '2-digit',
				})}
			</Text>
		);
	};

	return (
		<DetailRow itemName={formatMessage(i18n.created)} icon={<ClockIcon label="" />}>
			{renderCreatedItem()}
		</DetailRow>
	);
};

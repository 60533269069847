import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { useIntl, defineMessages } from 'react-intl';

import { token } from '@atlaskit/tokens';

import { ClassificationLozenge } from '@atlassian/data-classification-level';

import type { ClassificationLevel } from '../ClassificationRadioOption';

const i18n = defineMessages({
	spaceDefaultClassificationOption: {
		id: 'data-classification.space-default-classification-option',
		defaultMessage: 'Space default',
		description: 'This is the space default option',
	},
	noClassificationOption: {
		id: 'data-classification.no-classification-option',
		defaultMessage: 'Space default - No classification',
		description: 'This is no classification option',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowWrapper = styled.span({
	overflow: 'hidden',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceDefaultTextWrapper = styled.span({
	position: 'relative',
	top: token('space.negative.025'),
});

export const useSpaceDefaultClassificationLevelOption = (value?: ClassificationLevel | null) => {
	const intl = useIntl();

	return useMemo(() => {
		if (!value) {
			const noClassificationOption: ClassificationLevel = {
				id: i18n.noClassificationOption.id,
				definition: intl.formatMessage(i18n.noClassificationOption),
				color: 'GREY',
				includeIcon: false,
			};

			return noClassificationOption;
		}

		return {
			id: i18n.spaceDefaultClassificationOption.id,
			nameFormatter: (classification: ClassificationLevel) => (
				<OverflowWrapper>
					<SpaceDefaultTextWrapper>
						{intl.formatMessage(i18n.spaceDefaultClassificationOption)} -{' '}
					</SpaceDefaultTextWrapper>
					<ClassificationLozenge {...classification} name={value?.name || ''} />
				</OverflowWrapper>
			),
			color: value?.color,
			definition: '',
			classificationOverride: value,
		};
	}, [value, intl]);
};

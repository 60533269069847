import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';
import { ContentStatus } from '@confluence/page-utils/entry-points/enums';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { errorContainsCodes, PERMISSION_ERROR_CODES } from '../detailsPanelUtils';
import { DetailsPanelContentViewsQuery } from '../queries/DetailsPanelContentViewsQuery.graphql';
import type {
	DetailsPanelContentViewsQueryVariables as ContentViewsQueryVariables,
	DetailsPanelContentViewsQuery as ContentViewsRowQuery,
} from '../queries/__types__/DetailsPanelContentViewsQuery';
import { normalizeContentViewsData } from '../normalizers/normalizeContentViewsData';

export const useContentViews = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch } = useQuery<
		ContentViewsRowQuery,
		ContentViewsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentViewsQuery,
		{
			variables: {
				contentId: contentId ?? '',
				isDraft: contentStatus === ContentStatus.DRAFT,
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const isPermissionError = error && errorContainsCodes(error, PERMISSION_ERROR_CODES);

	const normalizedData = useMemo(() => normalizeContentViewsData(data), [data]);
	const { count, isInternalUser, isSSRError } = normalizedData;
	const contentViewsError = contentStatusError || error;

	return {
		data: normalizedData,
		loading,
		error: contentViewsError,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
		shouldHideViewsAnalytics: Boolean(
			isPermissionError || !isInternalUser || isSSRError || !count || contentViewsError || loading,
		),
	};
};

import React, { type FC } from 'react';

import Link from '@atlaskit/link';
import { Text, Pressable, xcss, Flex, Box } from '@atlaskit/primitives';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';

const pressableStyles = xcss({
	':hover': {
		textDecoration: 'underline',
	},
});

type SubtleLinkProps = {
	href?: string;
	onClick?: () => void;
	onClickDisabled?: boolean;
	hideChevron?: boolean;
	children?: React.ReactNode;
};

export const SubtleLink: FC<SubtleLinkProps> = ({
	href,
	children,
	onClick,
	onClickDisabled = false,
	hideChevron = false,
}) => {
	const linkContent = (
		<Text color="color.text.subtle">
			<Flex alignItems="center" gap="space.150">
				{children}
				{!hideChevron && <ChevronRightIcon label="" />}
			</Flex>
		</Text>
	);

	return href ? (
		<Box padding="space.100">
			<Link appearance="subtle" href={href}>
				{linkContent}
			</Link>
		</Box>
	) : (
		<Pressable
			onClick={onClick}
			padding="space.100"
			backgroundColor="color.background.neutral.subtle"
			xcss={pressableStyles}
			isDisabled={onClickDisabled}
		>
			{linkContent}
		</Pressable>
	);
};

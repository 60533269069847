import { createIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { messages } from '../messages';

import { type ClassificationOption, type Color } from './types';

const { formatMessage } = createIntl({ locale: 'en' });

export const NO_CLASSIFICATION_OPTION: ClassificationOption = {
	definition: formatMessage(messages.noClassification),
	includeIcon: false,
};

export const nameMaxLength = 50;
export const definitionMaxLength = 1000;
export const guidelineMaxLength = 1000;

export type ColorValues = {
	fontColor: string;
	iconColor: string;
	bgColor: string;
	bgColorHovered: string;
	bgColorPressed: string;
	bgColorFocused?: string;
};

export type ColorsConfig = Record<Color, ColorValues>;

export type DisabledColorConfig = Pick<ColorValues, 'iconColor' | 'fontColor' | 'bgColor'>;

export const colorsConfig: ColorsConfig = {
	YELLOW: {
		iconColor: token('color.icon.accent.yellow'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.yellow.subtlest'),
		bgColorHovered: token('color.background.accent.yellow.subtlest.hovered'),
		bgColorPressed: token('color.background.accent.yellow.subtlest.pressed'),
		bgColorFocused: token('color.background.accent.yellow.subtlest'),
	},
	ORANGE: {
		iconColor: token('color.icon.accent.orange'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.orange.subtle'),
		bgColorHovered: token('color.background.accent.orange.subtle.hovered'),
		bgColorPressed: token('color.background.accent.orange.subtle.pressed'),
		bgColorFocused: token('color.background.accent.orange.subtle'),
	},
	RED: {
		iconColor: token('color.icon.accent.red'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.red.subtler'),
		bgColorHovered: token('color.background.accent.red.subtler.hovered'),
		bgColorPressed: token('color.background.accent.red.subtler.pressed'),
		bgColorFocused: token('color.background.accent.red.subtler'),
	},
	RED_BOLD: {
		iconColor: token('color.icon.inverse'),
		fontColor: token('color.text.inverse'),
		bgColor: token('color.background.accent.red.bolder'),
		bgColorHovered: token('color.background.accent.red.bolder.hovered'),
		bgColorPressed: token('color.background.accent.red.bolder.pressed'),
		bgColorFocused: token('color.background.accent.red.bolder'),
	},
	PURPLE: {
		iconColor: token('color.icon.accent.purple'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.purple.subtlest'),
		bgColorHovered: token('color.background.accent.purple.subtlest.hovered'),
		bgColorPressed: token('color.background.accent.purple.subtlest.pressed'),
		bgColorFocused: token('color.background.accent.purple.subtlest'),
	},
	BLUE: {
		iconColor: token('color.icon.accent.blue'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.blue.subtler'),
		bgColorHovered: token('color.background.accent.blue.subtler.hovered'),
		bgColorPressed: token('color.background.accent.blue.subtler.pressed'),
		bgColorFocused: token('color.background.accent.blue.subtler'),
	},
	TEAL: {
		iconColor: token('color.icon.accent.teal'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.teal.subtler'),
		bgColorHovered: token('color.background.accent.teal.subtler.hovered'),
		bgColorPressed: token('color.background.accent.teal.subtler.pressed'),
		bgColorFocused: token('color.background.accent.teal.subtler'),
	},
	GREEN: {
		iconColor: token('color.icon.accent.green'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.green.subtlest'),
		bgColorHovered: token('color.background.accent.green.subtlest.hovered'),
		bgColorPressed: token('color.background.accent.green.subtlest.pressed'),
		bgColorFocused: token('color.background.accent.green.subtlest'),
	},
	GREY: {
		iconColor: token('color.icon.accent.gray'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.gray.subtlest'),
		bgColorHovered: token('color.background.accent.gray.subtlest.hovered'),
		bgColorPressed: token('color.background.accent.gray.subtlest.pressed'),
		bgColorFocused: token('color.background.accent.gray.subtlest'),
	},
	LIME: {
		iconColor: token('color.icon.accent.lime'),
		fontColor: token('color.text'),
		bgColor: token('color.background.accent.lime.subtler'),
		bgColorHovered: token('color.background.accent.lime.subtler.hovered'),
		bgColorPressed: token('color.background.accent.lime.subtler.pressed'),
		bgColorFocused: token('color.background.accent.lime.subtler'),
	},
};

// Doesn't exist within `colorsConfig` since it's not a valid `Color` type
export const disabledColorConfig: DisabledColorConfig = {
	iconColor: token('color.icon.disabled'),
	fontColor: token('color.text.disabled'),
	bgColor: token('color.background.disabled'),
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AvatarGroup from '@atlaskit/avatar-group';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import Skeleton from '@atlaskit/skeleton';

import { useContentContributors } from '../hooks/useContentContributors';

import { InlineRetryError } from './InlineRetryError';
import { DetailRow } from './DetailRow';

const i18n = defineMessages({
	contributors: {
		id: 'details-panel.contributor-row.contributors',
		defaultMessage: 'Contributors',
		description: "A title that accompanies a list of the content's contributors",
	},
});

export const ContributorsRow = () => {
	const { formatMessage } = useIntl();
	const { data, error, refetch, loading } = useContentContributors();
	const { nodes } = data;
	if (nodes.length === 0 && !error) return null;

	const renderContributorsSectionContent = () => {
		if (loading) {
			return (
				<Skeleton width="80px" height="24px" borderRadius={3} testId="contributors-row-loading" />
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return <AvatarGroup size="small" maxCount={3} appearance="stack" data={nodes ?? []} />;
	};

	return (
		<DetailRow itemName={formatMessage(i18n.contributors)} icon={<PeopleGroupIcon label="" />}>
			{renderContributorsSectionContent()}
		</DetailRow>
	);
};

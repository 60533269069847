import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Box, Inline, Text } from '@atlaskit/primitives';
import ChangesIcon from '@atlaskit/icon/core/changes';
import { IconButton } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import Skeleton from '@atlaskit/skeleton';
import Link from '@atlaskit/link';

import {
	ContentOwnershipContextProvider,
	ContentOwnershipContextConsumer,
} from '@confluence/content-ownership';
import { ProfileCardWrapper } from '@confluence/profile';
import { PEOPLE_DIRECTORY } from '@confluence/named-routes';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { useContentOwner } from '../hooks/useContentOwner';
import { useIsChangeOwnerAvailable } from '../hooks/useIsChangeOwnerAvailable';

import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	owner: {
		id: 'details-panel.owner-section.owner',
		defaultMessage: 'Owner',
		description: 'A label distinguishing the user as the owner of a page',
	},
	creator: {
		id: 'details-panel.owner-section.creator',
		defaultMessage: 'Creator',
		description: 'A label distinguishing the user as the person who created the blogpost',
	},
	changePageOwner: {
		id: 'details-panel.owner-section.change-page-owner',
		defaultMessage: 'Change owner',
		description: 'An icon label within a button used to change the owner of a page',
	},
	accessibleAvatarLabel: {
		id: 'details-panel.owner-section.accessible-avatar-label',
		defaultMessage: '{displayName}’s profile picture',
		description: "Alt text and aria-label for a user's profile picture",
	},
});

type ContentOwnershipContext = {
	isModalOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
};

export const OwnerSection = () => {
	const { formatMessage } = useIntl();
	const { type: contentType } = useContentStatus();
	const { data, error, refetch, loading } = useContentOwner();
	const { isChangeOwnerAvailable } = useIsChangeOwnerAvailable();

	const { accountId, profilePicturePath, displayName } = data;
	const profileURL = accountId ? `${PEOPLE_DIRECTORY.toUrl()}/${accountId}` : '';

	// The concept of "ownership" doesn't apply to blogposts for some reason, so blogposts should refer to "creator"
	const ownerOrCreatorString =
		contentType === 'blogpost' ? formatMessage(i18n.creator) : formatMessage(i18n.owner);

	const renderOwnerSectionContent = () => {
		if (loading) {
			return (
				<Inline alignBlock="center" space="space.150" testId="owner-section-loading">
					<Skeleton width="40px" height="40px" borderRadius={200} />
					<Skeleton width="154px" height="32px" borderRadius={4} />
				</Inline>
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline spread="space-between" alignBlock="center">
				<Inline alignBlock="center" space="space.100">
					<ProfileCardWrapper userId={accountId}>
						<Avatar
							size="large"
							href={profileURL}
							src={profilePicturePath ?? undefined}
							name={formatMessage(i18n.accessibleAvatarLabel, {
								displayName,
							})}
						/>
					</ProfileCardWrapper>
					<Box>
						<Box>
							{/* To be replaced by Link introduced in https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/163767/overview */}
							<Link appearance="subtle" href={profileURL}>
								{displayName}
							</Link>
						</Box>
						<Text size="small" color="color.text.subtle">
							{ownerOrCreatorString}
						</Text>
					</Box>
				</Inline>
				{isChangeOwnerAvailable && (
					<ContentOwnershipContextProvider>
						<ContentOwnershipContextConsumer>
							{({ openModal }: ContentOwnershipContext) => (
								<Tooltip content={formatMessage(i18n.changePageOwner)}>
									<IconButton
										appearance="subtle"
										icon={ChangesIcon}
										label={formatMessage(i18n.changePageOwner)}
										onClick={openModal}
									/>
								</Tooltip>
							)}
						</ContentOwnershipContextConsumer>
					</ContentOwnershipContextProvider>
				)}
			</Inline>
		);
	};

	return <Box testId="owner-section">{renderOwnerSectionContent()}</Box>;
};

import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentContributorsQuery } from '../queries/DetailsPanelContentContributorsQuery.graphql';
import type {
	DetailsPanelContentContributorsQuery as ContentContributorsQuery,
	DetailsPanelContentContributorsQueryVariables as ContentContributorsQueryVariables,
} from '../queries/__types__/DetailsPanelContentContributorsQuery';
import { normalizeContentContributorsData } from '../normalizers/normalizeContentContributorsData';

import { useContentOwner } from './useContentOwner';
import { getFormattedNodes } from './contributorUtils';

export const MAX_32BIT_INT = 2147483647;

export const useContentContributors = () => {
	const [contentId] = usePageContentId();
	const {
		data: contentOwnerData,
		refetch: refetchContentOwner,
		error: contentOwnerError,
		loading: isContentOwnerLoading,
	} = useContentOwner();

	const { accountId: ownerAccountId } = contentOwnerData;

	const {
		data: contentContributorsData,
		loading: isContentContributorsLoading,
		error: contentContributorsError,
		refetch: refetchContentContributors,
	} = useQuery<ContentContributorsQuery, ContentContributorsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentContributorsQuery,
		{
			variables: {
				contentId: contentId ?? '',
				contributorLimit: MAX_32BIT_INT,
			},
			skip: !contentId,
		},
	);

	const formattedNodes = useMemo(() => {
		const { nodes } = normalizeContentContributorsData(contentContributorsData);

		return getFormattedNodes({ nodes, ownerAccountId });
	}, [contentContributorsData, ownerAccountId]);

	return {
		data: {
			nodes: formattedNodes,
			count: formattedNodes.length,
		},
		loading: isContentOwnerLoading || isContentContributorsLoading,
		error: contentOwnerError || contentContributorsError,
		refetch: contentOwnerError ? refetchContentOwner : refetchContentContributors,
	};
};

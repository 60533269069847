/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { RovoIcon } from '@atlaskit/logo';
import { Box, Flex, Pressable } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';

const atlaskitCssStyles = cssMap({
	pressableStyles: {
		position: 'relative',
		height: '46px',
		width: '46px',
		borderRadius: token('border.radius.300'),
		backgroundColor: token('color.background.brand.boldest'),
		'&:hover': {
			backgroundColor: token('color.background.brand.boldest.hovered'),
		},
	},
	boxStyles: {
		maxWidth: '46px',
		position: 'relative',
		// @ts-expect-error
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		boxShadow: '0px 4px 4px 0px #00000040',
		transition: 'all .2s ease-in-out',
		borderRadius: token('border.radius.300'),
		'&:hover': {
			transform: 'scale(1.2)',
		},
		'&::after': {
			position: 'absolute',
			backgroundImage:
				'conic-gradient(from 180deg at 50% 50%, #FFA900 -52.2deg, #0065FF 89.76deg, #0469FF 145.8deg, #BF63F3 262.8deg, #FFA900 307.8deg, #0065FF 449.76deg);',
			content: "''",
			zIndex: -1,
			width: `100%`,
			height: `100%`,
			// @ts-expect-error
			top: '-1px',
			// @ts-expect-error
			left: '-1px',
			// @ts-expect-error
			borderRadius: '13px',
			// @ts-expect-error
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			padding: '1px',
		},
	},
});

export type AIButtonProps = {
	onClick: () => void;
};

export const AIButton = ({ onClick }: AIButtonProps) => {
	return (
		// @ts-expect-error
		// eslint-disable-next-line  @compiled/no-suppress-xcss
		<Box xcss={atlaskitCssStyles.boxStyles}>
			<Pressable
				testId="platform-ai-button"
				xcss={atlaskitCssStyles.pressableStyles}
				onClick={onClick}
			>
				<Flex alignItems="center" justifyContent="center">
					<RovoIcon size="small" appearance="inverse"></RovoIcon>
				</Flex>
			</Pressable>
		</Box>
	);
};

import { defineMessages } from 'react-intl-next';

export const sharedMessages = defineMessages({
	fetchErrorTextPrimary: {
		id: 'data-classification.shared-messages.fetch-error.primary',
		description: 'Error message shown if classifications failed to load',
		defaultMessage: 'Unable to load classification. Try again.',
	},
	fetchErrorTextSecondary: {
		id: 'data-classification.shared-messages.fetch-error.secondary',
		defaultMessage: 'Try reloading now.',
		description: 'Secondary text for classification tag loading error',
	},
	saveErrorTextPrimary: {
		id: 'data-classification.shared-messages.save-error.primary',
		defaultMessage: 'Update to classification failed to save',
		description: "Title for error flag shown when page's classification failed to save",
	},
	saveErrorTextSecondary: {
		id: 'data-classification.shared-messages.save-error.secondary',
		defaultMessage: 'Please try again.',
		description: "Secondary message for error flag shown when page's classification failed to save",
	},
});

import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ProjectStatusIcon from '@atlaskit/icon/core/project-status';
import { Box, Text, Inline, xcss } from '@atlaskit/primitives';

import {
	ContentStateColor,
	EditorContentState,
	type ContentState,
} from '@confluence/content-state';
import { OperationTypes, useCanPerformContentOperation } from '@confluence/entity-operations';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useContentType, usePageContentId } from '@confluence/page-context';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';
import {
	PageMode,
	ContentStatus,
	CURRENT_STATUS,
	DRAFT_STATUS,
} from '@confluence/page-utils/entry-points/enums';

import { useContentState, type AvailableContentState } from '../hooks/useContentState';
import { useIsDisconnected } from '../hooks/useIsDisconnected';

import { DetailRow } from './DetailRow';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	status: {
		id: 'details-panel.properties-section.status-row',
		defaultMessage: 'Status',
		description:
			"Text that appears next to the content's status (rough draft, in-progress, ready for review)",
	},
});

const readOnlyStatusStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.025',
});

const readOnlyTextStyles = xcss({
	marginLeft: 'space.025',
	marginRight: 'space.025',
});

type StatusRowComponentProps = ContentState & {
	contentId?: string;
	contentType?: string;
	userOffline: boolean;
	isEditAllowed: boolean;
};

const StatusRowComponent = ({
	color,
	restrictionLevel,
	name,
	contentId,
	contentType,
	userOffline,
	isEditAllowed,
}: StatusRowComponentProps) =>
	isEditAllowed ? (
		<EditorContentState
			contentId={contentId}
			contentType={contentType}
			userOffline={userOffline}
			renderPlusButtonEmptyState
			isDetailsPanel
		/>
	) : (
		<Inline alignBlock="center" xcss={[readOnlyStatusStyles]}>
			<ContentStateColor
				color={color}
				// (Make sure that the colorAppearance is set to the same value as used in the editable
				//  version found in next/packages/content-state/src/EditorContentState/ContentStateButton.tsx)
				appearance="rendererContentState"
				restrictionLevel={restrictionLevel}
			/>
			<Box xcss={[readOnlyTextStyles]}>
				<Text>{name}</Text>
			</Box>
		</Inline>
	);

export const StatusRow = (contentState: AvailableContentState) => {
	const { formatMessage } = useIntl();
	const { data, error, refetch } = useContentState(contentState);
	const { color, restrictionLevel, name } = data;
	const isContentStateAvailable = !!color || !!restrictionLevel || !!name;

	const pageMode = useGetPageMode();
	const [contentId] = usePageContentId();
	const isDisconnected = useIsDisconnected();
	const [contentType] = useContentType();
	const { contentStatus } = useContentStatus();
	const [{ isViewMode: isLiveViewMode }] = useLivePageMode();

	const isContentStateEditable =
		pageMode === PageMode.EDIT || (pageMode === PageMode.LIVE && !isLiveViewMode);

	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType,
		operation: OperationTypes.UPDATE,
		status: contentStatus === ContentStatus.DRAFT ? DRAFT_STATUS : CURRENT_STATUS,
	});
	const hasEditPermission = canUpdateContent ?? false;

	const isEditAllowed = isContentStateEditable && hasEditPermission;

	// If we don't have a content state and the user is unable to add one (either from the content
	// mode they're in or because they don't have permissions), we should hide the entire row.  But
	// if there is an error, we should show the row with an error message.
	if (!isContentStateAvailable && !isEditAllowed && !error) {
		return null;
	}

	return (
		<DetailRow itemName={formatMessage(i18n.status)} icon={<ProjectStatusIcon label="" />}>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<StatusRowComponent
					name={name}
					color={color}
					restrictionLevel={restrictionLevel}
					contentId={contentId}
					contentType={contentType}
					userOffline={isDisconnected}
					isEditAllowed={isEditAllowed}
				/>
			)}
		</DetailRow>
	);
};

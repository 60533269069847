import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';

const i18n = defineMessages({
	detailsPanelHeaderIcon: {
		id: 'details-panel.panel.header.icon.label',
		defaultMessage: 'Details Panel Icon',
		description: 'Label for the details panel header icon',
	},
});

export const DetailsPanelHeaderIcon = () => {
	const { formatMessage } = useIntl();

	return <AlignTextLeftIcon label={formatMessage(i18n.detailsPanelHeaderIcon)} />;
};

import { useState, useEffect, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { usePageContentId } from '@confluence/page-context';

import { fetchJiraMetadata } from '../rest/fetchJiraMetadata';
import { type JiraMetadata } from '../constants/jiraMetaData';

interface UseGetJiraMetadata {
	data: JiraMetadata | null;
	error: Error | null;
	isLoading: boolean;
	refetch: () => Promise<void>;
}

export const useGetJiraMetadata = (): UseGetJiraMetadata => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [contentId] = usePageContentId();
	const [data, setData] = useState<JiraMetadata | null>(null);
	const [fetchError, setFetchError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const fireSuccessAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'success',
				actionSubject: 'fetchJiraMetadata',
				source: 'linkedJiraWorkItems',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const fireFailureAnalytics = useCallback(
		(error: unknown) => {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'failure',
					actionSubject: 'fetchJiraMetadata',
					source: 'linkedJiraWorkItems',
					attributes: {
						error,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const getJiraMetadata = useCallback(async () => {
		if (!contentId) return;

		setIsLoading(true);
		try {
			const jiraMetaData = await fetchJiraMetadata(contentId);

			if (!jiraMetaData) {
				throw new Error('No Jira metadata has been fetched');
			}

			fireSuccessAnalytics();
			setData(jiraMetaData);
		} catch (error) {
			fireFailureAnalytics(error);
			setFetchError(
				error instanceof Error ? error : new Error('Request to getJiraMetadata failed'),
			);
		} finally {
			setIsLoading(false);
		}
	}, [contentId, fireSuccessAnalytics, fireFailureAnalytics]);

	useEffect(() => {
		void getJiraMetadata();
	}, [getJiraMetadata]);

	return {
		data,
		error: fetchError,
		isLoading,
		refetch: getJiraMetadata,
	};
};

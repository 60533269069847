import React, { useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';
import ClockIcon from '@atlaskit/icon/core/clock';
import CommentIcon from '@atlaskit/icon/core/comment';

import { useDynamicSurfacePanel } from '@atlassian/ai-smart-button';
import { useAISmartButton } from '@atlassian/ai-smart-button-api';

import { QuickSummaryStreamingComponent } from '@confluence/quick-summary';
import {
	CommentsSummarySource,
	FooterCommentsSummaryCombined,
} from '@confluence/ai-comments-summary';
import { PageCatchupComponent } from '@confluence/page-catchup';
import { CommentCountsContext, CommentCountsProvider } from '@confluence/comment-context';
import { useObjectSidebarActions } from '@confluence/object-sidebar-api';

import { CatchupContentType } from '../__types__/CatchupLastViewedTimeQuery';

import { useDynamicSplitButton } from './useDynamicSplitButton';
import { useCreateObjectSidebarProp } from './useCreateObjectSidebarProp';

export const i18n = defineMessages({
	inlineCommentsMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.inline-comments',
		defaultMessage: 'Summarize comments',
		description: 'Label for the AI Content Actions Inline Comments Menu Item',
	},
	pageCatchupMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.page-catchup',
		defaultMessage: 'Summarize changes',
		description: 'Label for the AI Content Actions Page Catchup Menu Item',
	},
	summarizeSectionLabel: {
		id: 'ai-floating-context-menu.summarize.section.label',
		defaultMessage: 'Summarize',
		description: 'Label for the summarize section in the menu group',
	},
});

const VIEW_PAGE_SOURCE_ID = 'view-page';

type AIConsumptionViewPageProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	lastModifiedDate?: string | null;
};

export const useAIConsumptionViewPage = ({
	contentId,
	contentType,
	spaceKey,
	lastModifiedDate,
}: AIConsumptionViewPageProps) => {
	const { formatMessage } = useIntl();
	const [_, { registerActions, removeAll, registerNudges }] = useAISmartButton();
	const { unresolvedInlineCommentsCount, footerCommentsCount } = useContext(CommentCountsContext);
	const { showObjectSidebar } = useObjectSidebarActions();

	const objectSidebar = useCreateObjectSidebarProp();
	const dynamicSurfacePanel = useDynamicSurfacePanel(objectSidebar);

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;
	const { showCatchupAsDefault } = useDynamicSplitButton(contentId, contentTypeEnum);

	useEffect(() => {
		const nudges = [
			{
				id: 'summarize-changes-ai-action',
				title: formatMessage(i18n.pageCatchupMenuItemLabel),
				sourceId: VIEW_PAGE_SOURCE_ID,
				icon: <ClockIcon label="" />,
				onClick: () => showObjectSidebar(dynamicSurfacePanel, 'push'),
				viewConfig: {
					render: () => (
						<PageCatchupComponent
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
							isInObjectSidebar
						/>
					),
				},
			},
		];

		const actions = [
			{
				id: 'summarize-page-ai-action',
				title: formatMessage(i18n.summarizeSectionLabel),
				sourceId: VIEW_PAGE_SOURCE_ID,
				icon: <ListBulletedIcon label="" />,
				viewConfig: {
					render: () => (
						<QuickSummaryStreamingComponent
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							lastModifiedDate={lastModifiedDate ?? ''}
							source="view-page"
							setIsOpen={() => {}}
							contentPath={window.location.pathname}
							isInObjectSidebar
						/>
					),
				},
			},
			...nudges,
		];

		if (unresolvedInlineCommentsCount > 0 || footerCommentsCount > 0) {
			actions.push({
				id: 'summarize-comments-ai-action',
				title: formatMessage(i18n.inlineCommentsMenuItemLabel),
				sourceId: VIEW_PAGE_SOURCE_ID,
				icon: <CommentIcon label="" />,
				viewConfig: {
					render: () => (
						<CommentCountsProvider contentId={contentId}>
							<FooterCommentsSummaryCombined
								contentId={contentId}
								contentType={contentType}
								source={CommentsSummarySource.Footer}
								spaceKey={spaceKey}
								isInObjectSidebar
							/>
						</CommentCountsProvider>
					),
				},
			});
		}

		// Removing all view page actions before registering to avoid duplication.
		removeAll(VIEW_PAGE_SOURCE_ID);
		registerActions(actions);

		if (showCatchupAsDefault) {
			registerNudges(nudges);
		}
	}, [
		removeAll,
		registerActions,
		formatMessage,
		contentId,
		spaceKey,
		contentType,
		lastModifiedDate,
		unresolvedInlineCommentsCount,
		footerCommentsCount,
		registerNudges,
		dynamicSurfacePanel,
		showObjectSidebar,
		showCatchupAsDefault,
	]);

	useEffect(
		() => {
			return () => removeAll(VIEW_PAGE_SOURCE_ID);
		},
		// clean-up on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
};

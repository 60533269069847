import { useQuery } from 'react-apollo';

import { useCanPerformContentOperation, OperationTypes } from '@confluence/entity-operations';
import { isStatusCodeError } from '@confluence/error-types';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePageContentId, useContentType } from '@confluence/page-context';

import type {
	DetailsPanelRedactionsRowQuery as RedactionsQuery,
	DetailsPanelRedactionsRowQueryVariables as RedactionsQueryVariables,
} from '../queries/__types__/DetailsPanelRedactionsRowQuery';
import { DetailsPanelRedactionsRowQuery } from '../queries/DetailsPanelRedactionsRowQuery.graphql';

export const useContentRedactions = () => {
	const [contentType] = useContentType();
	const [contentId] = usePageContentId();

	// Only users with "Edit" permission should be able to see the redaction metadata
	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType,
		operation: OperationTypes.UPDATE,
	});

	const { data, error, refetch } = useQuery<RedactionsQuery, RedactionsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		DetailsPanelRedactionsRowQuery,
		{
			skip: !contentId || !canUpdateContent,
			variables: {
				contentId: contentId ?? '',
			},
			errorPolicy: 'all',
		},
	);

	if (isStatusCodeError(error, '404')) markErrorAsHandled(error);

	// As we will render nothing for the item, if loading, unentitled, or no redactions,
	// zero is fine as a default
	const redactionMetadataCount = data?.singleContent?.redactionMetadataCount ?? 0;

	return {
		redactionMetadataCount,
		error: !isStatusCodeError(error, '404') ? error : null,
		refetch,
	};
};

import { defineMessages } from 'react-intl-next';

import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';

import { DetailsPanelHeaderIcon } from './DetailsPanelHeaderIcon';
import { DetailsPanelBody } from './DetailsPanelBody';

export const i18n = defineMessages({
	detailsPanelHeaderLabel: {
		id: 'details-panel.panel.header.label',
		defaultMessage: 'Details',
		description: 'Label for the details panel header',
	},
});

export const useShowDetailsPanel = () => {
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar }] = useObjectSidebar();

	const showDetailsPanel = () => {
		showObjectSidebar(
			{
				id: PanelName.DetailsPanel,
				headerComponentElements: {
					HeaderIcon: DetailsPanelHeaderIcon,
					headerLabel: i18n.detailsPanelHeaderLabel,
				},
				BodyComponent: DetailsPanelBody,
			},
			'push',
		);
	};

	return {
		isDetailsPanelShown: isObjectSidebarShown && panel?.id === PanelName.DetailsPanel,
		showDetailsPanel,
	};
};

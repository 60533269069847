import { token } from '@atlaskit/tokens';

export const PRIMARY_COLORS = {
	BLUE: token('color.background.accent.blue.subtle'),
	GREEN: token('color.background.accent.green.subtle'),
	YELLOW: token('color.background.accent.yellow.subtle'),
	RED: token('color.background.accent.red.subtle'),
	PURPLE: token('color.background.accent.purple.subtle'),
	VERIFIED: token('color.icon.accent.blue'),
};

export const SECONDARY_COLORS = {
	BLUE: token('color.background.accent.blue.subtlest'),
	GREEN: token('color.background.accent.green.subtlest'),
	YELLOW: token('color.background.accent.yellow.subtlest'),
	RED: token('color.background.accent.red.subtlest'),
	PURPLE: token('color.background.accent.purple.subtlest'),
};

export const HOVER_COLORS = {
	BLUE: token('color.background.accent.blue.subtlest.hovered'),
	GREEN: token('color.background.accent.green.subtlest.hovered'),
	YELLOW: token('color.background.accent.yellow.subtlest.hovered'),
	RED: token('color.background.accent.red.subtlest.hovered'),
	PURPLE: token('color.background.accent.purple.subtlest.hovered'),
};

export const COLOR_NAMES = {
	BLUE: 'blue',
	GREEN: 'green',
	YELLOW: 'yellow',
	RED: 'red',
	PURPLE: 'purple',
};

import React, { useState, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { xcss, Flex } from '@atlaskit/primitives';
import { Manager, Popper, Reference, type PopperProps } from '@atlaskit/popper';
import Tooltip from '@atlaskit/tooltip';
import EmojiAddIcon from '@atlaskit/icon/core/emoji-add';
import { IconButton } from '@atlaskit/button/new';
import { useCloseManager } from '@atlaskit/reactions';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';

import { fg } from '@confluence/feature-gating';
import { ReactionContainerType, ReactionLocation } from '@confluence/reactions';
import { ReactionsLoader } from '@confluence/reactions/entry-points/ReactionsLoader';

const i18n = defineMessages({
	addReaction: {
		id: 'object-sidebar-components.reaction.button.add.reaction',
		defaultMessage: 'Add a reaction',
		description: 'Message in tooltip which tells user to add a reaction.',
	},
	moreEmojis: {
		id: 'object-sidebar-components.reaction.button.more.emojis',
		description: 'Message displayed in button tooltip to show more emojis.',
		defaultMessage: 'More emojis',
	},
});

type ObjectSidebarReactionsPickerProps = {
	contentId: string;
	spaceId: string;
	isDisabled?: boolean;
	contentType?: string;
	contentSubType?: string;
	reactionsPickerPreventOverflowOptions?: Record<string, any>;
};

const popperModifiers: PopperProps<{}>['modifiers'] = [
	/*
		Removing this applyStyle modifier as it throws client errors ref:
		https://popper.js.org/docs/v1/#modifiers
		https://popper.js.org/docs/v1/#modifiers..applyStyle
		{ name: 'applyStyle', enabled: false },
   */
	{
		name: 'hide',
		enabled: false,
	},
	{
		name: 'offset',
		enabled: true,
		options: {
			offset: [0, 0],
		},
	},
	{
		name: 'flip',
		enabled: true,
		options: {
			flipVariations: true,
			boundariesElement: 'scrollParent',
		},
	},
	{
		name: 'preventOverflow',
		enabled: true,
	},
];

const popupStyles = xcss({
	width: 'max-content',
	maxWidth: '448px',
	padding: 'space.100',
	backgroundColor: 'color.background.input',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.raised',
	marginRight: 'space.200',
});

export const ObjectSidebarReactionsPicker = ({
	contentId,
	spaceId,
	isDisabled = false,
	contentType,
	contentSubType,
	reactionsPickerPreventOverflowOptions,
}: ObjectSidebarReactionsPickerProps) => {
	const { formatMessage } = useIntl();

	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
	// Container <div /> reference (used by custom hook to detect click outside)
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [triggerRef, setTriggerRef] = useState<HTMLButtonElement | null>(null);

	const containerType =
		contentType === 'blogpost' ? ReactionContainerType.BLOGPOST : ReactionContainerType.PAGE;
	const location = contentType === 'blogpost' ? ReactionLocation.BLOGPOST : ReactionLocation.PAGE;

	const handleClick = () => {
		setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
	};

	// Custom hook triggers when user clicks outside the reactions picker to close it.
	// Popper doesn't have this built in functionality for closing on outside clicks.
	useCloseManager(
		wrapperRef,
		(callbackType) => {
			setIsPopupOpen(false);
			if (triggerRef && callbackType === 'ESCAPE') {
				requestAnimationFrame(() => triggerRef.focus());
			}
		},
		true,
		isPopupOpen,
	);

	return (
		<div ref={wrapperRef}>
			<Manager>
				<Flex testId="object-side-bar-reactions" justifyContent="center" alignItems="center">
					<Reference>
						{({ ref }) => (
							<Tooltip content={formatMessage(i18n.addReaction)} position="left" hideTooltipOnClick>
								<IconButton
									ref={(node: HTMLButtonElement | null) => {
										if (node && isPopupOpen) {
											if (typeof ref === 'function') {
												ref(node);
											} else {
												(ref as React.MutableRefObject<HTMLButtonElement>).current = node;
											}
											setTriggerRef(node);
										}
									}}
									label={formatMessage(i18n.addReaction)}
									appearance="subtle"
									icon={EmojiAddIcon}
									shape={fg('ai-smart-button-team-25') ? 'default' : 'circle'}
									onClick={handleClick}
									testId="reactions-trigger"
									data-vc="object-sidebar-reactions"
								/>
							</Tooltip>
						)}
					</Reference>
					{isPopupOpen && (
						<Popper placement="left" strategy="absolute" modifiers={popperModifiers}>
							{({ ref, style }) => (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								<div ref={ref} style={style}>
									<Flex xcss={popupStyles} testId="reactions-popper-content">
										<ReactionsLoader
											contentId={contentId}
											location={location}
											containerId={spaceId}
											containerType={containerType}
											contentSubType={contentSubType}
											readOnly={isDisabled}
											showAddReactionText={false}
											summaryViewEnabled={false}
											pickerQuickReactionEmojiIds={[]} // Send empty array to display full emoji picker
											reactionPickerTriggerIcon={
												<ShowMoreHorizontalIcon label={formatMessage(i18n.moreEmojis)} />
											}
											reactionPickerTriggerToolipContent={formatMessage(i18n.moreEmojis)}
											hideNoReactionsText
											allowUserDialog
											isRenderedInObjectSidebar
											isSubtle
											showSubtleDefaultReactions
											useDefaultStyledContainer
											reactionPickerPlacement="top-end"
											reactionsPickerPreventOverflowOptions={
												reactionsPickerPreventOverflowOptions || {
													rootBoundary: 'scrollParent',
													altAxis: true,
													altBoundary: true,
												}
											}
										/>
									</Flex>
								</div>
							)}
						</Popper>
					)}
				</Flex>
			</Manager>
		</div>
	);
};

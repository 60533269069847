import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/core/error';
import { Inline, xcss, Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { ErrorDisplay } from '@confluence/error-boundary';

const retryStyle = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	color: 'color.text.information',
});

const i18n = defineMessages({
	retry: {
		id: 'details-panel.error-retry',
		defaultMessage: 'Retry',
		description: 'Text that prompts the user to retry fetching data',
	},
	errorInfo: {
		id: 'details-panel.error-info',
		defaultMessage: 'Failed to load',
		description: 'Text that lets the user know that an error occurred',
	},
	errorIconLabel: {
		id: 'details-panel.error-icon-label',
		defaultMessage: 'error icon',
		description: 'Label for error icon',
	},
});

type InlineRetryErrorProps = {
	error: Error;
	refetch: () => void;
};

export const InlineRetryError = ({ error, refetch }: InlineRetryErrorProps) => {
	const { formatMessage } = useIntl();
	return (
		<ErrorDisplay error={error}>
			<Inline alignBlock="center">
				<Tooltip position="bottom" content={formatMessage(i18n.errorInfo)}>
					<ErrorIcon
						color={token('color.icon.danger')}
						label={formatMessage(i18n.errorIconLabel)}
					/>
				</Tooltip>
				<Pressable xcss={retryStyle} onClick={() => refetch()}>
					{formatMessage(i18n.retry)}
				</Pressable>
			</Inline>
		</ErrorDisplay>
	);
};

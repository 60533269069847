import { isSpaceOverview } from '@confluence/named-routes';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useSpaceHomepageId } from '@confluence/space-utils';

type VisibilityKeys<T> = keyof T;

export const ELEMENTS = {
	ROWS: {
		CUSTOM_CONTENT: 'customContent',
	},
	SECTIONS: {
		PROPERTIES: 'properties',
		PAGE_MODE: 'pageMode',
	},
};

export const SPACE_OVERVIEW_ELEMENT_VISIBILITY = {
	[ELEMENTS.ROWS.CUSTOM_CONTENT]: false,
	[ELEMENTS.SECTIONS.PROPERTIES]: false,
	[ELEMENTS.SECTIONS.PAGE_MODE]: false,
};

type SpaceOverviewElementKey = VisibilityKeys<typeof SPACE_OVERVIEW_ELEMENT_VISIBILITY>;

const getDefaultElementVisibility = (): boolean => true;

const getSpaceOverviewElementVisibility = (element: SpaceOverviewElementKey): boolean =>
	SPACE_OVERVIEW_ELEMENT_VISIBILITY[element] ?? true;

export const useElementVisibility = () => {
	const isSpaceOverviewViewMode = isSpaceOverview();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const homepageId = useSpaceHomepageId(spaceKey);
	const isSpaceOverviewEditMode = homepageId === contentId;
	const isSpaceOverviewPage = isSpaceOverviewViewMode || isSpaceOverviewEditMode;

	const isElementVisible = isSpaceOverviewPage
		? getSpaceOverviewElementVisibility
		: getDefaultElementVisibility;

	return {
		isElementVisible,
	};
};

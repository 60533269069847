import { cfetch } from '@confluence/network';

import { type JiraMetadata } from '../constants/jiraMetaData';

const getJiraMetadataEndpoint = (contentId?: string) => {
	return `/wiki/rest/jira-metadata/1.0/metadata?pageId=${contentId}`;
};

export const fetchJiraMetadata = async (contentId: string): Promise<JiraMetadata> => {
	const response = await cfetch(getJiraMetadataEndpoint(contentId), {
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
		credentials: 'include',
	});

	return response.json();
};

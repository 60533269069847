import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import { IconButton } from '@atlaskit/button/new';

import { PanelName, useObjectSidebar } from '@confluence/object-sidebar-api';

import { DetailsPanelHeaderIcon } from '../DetailsPanelHeaderIcon';
import { DetailsPanelBody } from '../DetailsPanelBody';

export const i18n = defineMessages({
	detailsPanelHeaderLabel: {
		id: 'details-panel.panel.header.label',
		defaultMessage: 'Details',
		description: 'Label for the details panel header',
	},
	iconButtonLabel: {
		id: 'details-panel.jira-work-items-panel.header-navigation.icon-button',
		defaultMessage: 'return-to-content-details',
		description: 'Label for button that returns user to content details panel',
	},
	leftArrowIconLabel: {
		id: 'details-panel.jira-work-items-panel.header-navigation.left-arrow-icon',
		defaultMessage: 'back-icon',
		description: 'Label for icon on button that returns user to content details panel',
	},
});

export const JiraWorkItemsHeaderNavigation = () => {
	const { formatMessage } = useIntl();
	const [, { showObjectSidebar }] = useObjectSidebar();

	const showDetailsPanel = () => {
		showObjectSidebar(
			{
				id: PanelName.DetailsPanel,
				headerComponentElements: {
					HeaderIcon: DetailsPanelHeaderIcon,
					headerLabel: i18n.detailsPanelHeaderLabel,
				},
				BodyComponent: DetailsPanelBody,
			},
			'push',
		);
	};

	return (
		<IconButton
			icon={() => <ArrowLeftIcon label={formatMessage(i18n.leftArrowIconLabel)} spacing="none" />}
			appearance="subtle"
			label={formatMessage(i18n.iconButtonLabel)}
			onClick={showDetailsPanel}
			testId="naviagte-back-to-details-panel-button"
		/>
	);
};

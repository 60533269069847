import gql from 'graphql-tag';

export const DetailsPanelSingleContentStateQuery = gql`
	query DetailsPanelSingleContentStateQuery($contentId: ID!, $isDraft: Boolean!, $status: String!) {
		singleContent(id: $contentId, status: [$status]) {
			contentState(isDraft: $isDraft) {
				id
				name
				color
				restrictionLevel
			}
		}
	}
`;

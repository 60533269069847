import { useState, useEffect, useCallback } from 'react';

import { usePageContentId } from '@confluence/page-context';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';

import { getCustomContent } from '../customContentUtils';

export const useCustomContent = () => {
	const [contentId] = usePageContentId();
	const { type: contentType } = useContentStatus();
	const [customContentCount, setCustomContentCount] = useState<number>(0);
	const [customContentError, setCustomContentError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const fetchCustomContent = useCallback(async () => {
		if (!contentId) return;
		setCustomContentError(undefined);
		setCustomContentCount(0);

		if (navigator.onLine) {
			setLoading(true);

			try {
				const response = await getCustomContent(contentId, contentType);

				if ('errors' in response) {
					setCustomContentError(
						new Error(
							response.errors
								.map(
									({ code, detail, status, title }) => `${status} - ${code} - ${title} - ${detail}`,
								)
								.join(','),
						),
					);
				} else {
					setCustomContentCount(response.results.length);
				}
			} catch (error) {
				setCustomContentError(new Error(`500 - unknown - Error - ${error.message}`));
			} finally {
				setLoading(false);
			}
		}
	}, [contentId, contentType]);

	useEffect(() => {
		void fetchCustomContent();
	}, [fetchCustomContent]);

	return {
		data: {
			customContentCount,
		},
		error: customContentError,
		loading,
		refetch: fetchCustomContent,
	};
};

import React, { useState } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { Flex, Inline, xcss } from '@atlaskit/primitives';
import Button, { IconButton } from '@atlaskit/button/new';
import FeedbackCollector from '@atlaskit/feedback-collector';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import Tooltip from '@atlaskit/tooltip';
import { Field } from '@atlaskit/form';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { withFlags, type FlagsStateContainer } from '@confluence/flags';

const ENTRY_POINT_ID = 'a7ad93ff-d025-479e-beaf-8885e38fa5a9';
const FEEDBACK_CONTEXT_ID = 'customfield_10047';

const i18n = defineMessages({
	feedbackButtonTooltip: {
		id: 'object-sidebar-components.object-sidebar.feedback-collector-tooltip',
		defaultMessage: 'Give feedback on our new layout',
		description:
			'Tooltip message for button in the object sidebar to provide feedback on layout changes',
	},
	feedbackDialogTitle: {
		id: 'object-sidebar-components.object-sidebar.feedback-collector-title',
		defaultMessage: 'Help us improve the new layout',
		description: 'Title for the modal that allows users to provide feedback on layout changes',
	},
	tellUsMore: {
		id: 'object-sidebar-components.object-sidebar.tell-us-more',
		defaultMessage: 'Tell us more about your experience',
		description: 'Title for textbox where users can describe their experience',
	},
	rateExperience: {
		id: 'object-sidebar-components.object-sidebar.rate-experience',
		defaultMessage: 'Please rate your experience with the new layout:',
		description: 'Field label for rating the overall experience with the new layout',
	},
	feedbackCategory: {
		id: 'object-sidebar-components.object-sidebar.feedback-category',
		defaultMessage: 'What category best describes your feedback? (optional)',
		description: 'Field label for the categories of feedback a user will provide',
	},
	userInterfaceQuality: {
		id: 'object-sidebar-components.object-sidebar.user-interface-quality',
		defaultMessage: 'User interface quality',
		description: 'Label for a button that a user can select as a category for feedback',
	},
	featureSuggestion: {
		id: 'object-sidebar-components.object-sidebar.feature-suggestion',
		defaultMessage: 'Feature suggestion or request',
		description: 'Label for a button that a user can select as a category for feedback',
	},
	reportBug: {
		id: 'object-sidebar-components.object-sidebar.report-bug',
		defaultMessage: 'Report a bug',
		description: 'Label for a button that a user can select as a category for feedback',
	},
	successFlagTitle: {
		id: 'object-sidebar-components.object-sidebar.feedback-success-flag-title',
		defaultMessage: 'We’ve recorded your response',
		description: 'Title for flag after user successfully submits feedback',
	},
	successFlagDescription: {
		id: 'object-sidebar-components.object-sidebar.feedback-success-flag-description',
		defaultMessage: 'Thanks for taking the time to share your feedback with us.',
		description: 'Description for flag after user successfully submits feedback',
	},
});

export enum ExperienceRating {
	VeryDissatisfied = 'Very dissatisfied',
	Dissatisfied = 'Dissatisfied',
	Neutral = 'Neutral',
	Satisfied = 'Satisfied',
	VerySatisfied = 'Very satisfied',
}

const emojiSelectionContainer = xcss({
	width: '129px',
	paddingTop: 'space.100',
});

const feedbackCategoryContainer = xcss({
	paddingTop: 'space.100',
});

// NOTE: Re-used pill component from comments panel
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-html-button -- To migrate as part of go/ui-styling-standard
const PillButton = styled.button<{
	isSelected?: boolean;
}>({
	outline: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isSelected ? token('color.background.selected') : token('color.background.neutral'),
	display: 'inline-flex',
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? token('color.text.selected') : token('color.text')),
	cursor: 'pointer',
	userSelect: 'none',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected
				? token('color.background.selected.hovered')
				: token('color.background.neutral.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginLeft: 0,
	},

	padding: `${token('space.075')} ${token('space.150')}`,
	// TODO: Change B400 here to B700 when it becomes available
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) =>
		props.isSelected
			? `1px solid ${token('color.border.selected')}`
			: `1px solid ${token('color.background.neutral')}`,
	borderRadius: '90px',
	height: '32px',
	justifyContent: 'center',
	alignItems: 'center',
	'&:focus': {
		outline: `2px solid ${token('color.border.focused')}`,
		borderColor: 'transparent',
	},
});

type CategoryPillProps = {
	categoryName: string;
	selectedCategories: Record<string, boolean>;
	setSelectedCategories: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
	children?: React.ReactNode;
};

export const CategoryPill: React.FC<CategoryPillProps> = ({
	categoryName,
	selectedCategories,
	setSelectedCategories,
	children,
}) => {
	return (
		<PillButton
			isSelected={selectedCategories[categoryName]}
			onClick={(e) => {
				e.preventDefault();
				setSelectedCategories((prevCategories: Record<string, boolean>) => ({
					...prevCategories,
					[categoryName]: !prevCategories[categoryName],
				}));
			}}
		>
			{children}
		</PillButton>
	);
};

type ContentWrapperCustomFeedbackContentProps = {
	experienceRating: ExperienceRating;
	setExperienceRating: React.Dispatch<React.SetStateAction<ExperienceRating>>;
	selectedCategories: Record<string, boolean>;
	setSelectedCategories: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
};

const ContentWrapperCustomFeedbackContent: React.FC<ContentWrapperCustomFeedbackContentProps> = ({
	experienceRating,
	setExperienceRating,
	selectedCategories,
	setSelectedCategories,
}) => {
	const { formatMessage } = useIntl();
	const optionData = [
		{
			emojiId: '1f620',
			experienceRating: ExperienceRating.VeryDissatisfied,
		},
		{
			emojiId: '2639',
			experienceRating: ExperienceRating.Dissatisfied,
		},
		{
			emojiId: '1f610',
			experienceRating: ExperienceRating.Neutral,
		},
		{
			emojiId: '1f642',
			experienceRating: ExperienceRating.Satisfied,
		},
		{
			emojiId: '1f603',
			experienceRating: ExperienceRating.VerySatisfied,
		},
	];

	return (
		<>
			<Field
				label={formatMessage(i18n.rateExperience)}
				name="rate-experience"
				id="rate-experience"
				isRequired
			>
				{({ fieldProps }) => (
					<Flex
						direction="row"
						justifyContent="space-between"
						data-name={fieldProps.id}
						xcss={emojiSelectionContainer}
					>
						{optionData.map((option) => (
							<LazyEmojiComponentLoader
								emoji={option.emojiId}
								key={option.emojiId}
								height={18}
								context="contentWrapperFeedbackCollector"
								shouldRemoveEmojiLeftPadding
								wrapper={({ children }: { children?: React.ReactNode }) => (
									<Button
										appearance="subtle"
										data-name={option.experienceRating}
										data-value={option.experienceRating}
										onClick={(e) => {
											e.preventDefault();
											setExperienceRating(option.experienceRating);
										}}
										isSelected={experienceRating === option.experienceRating}
									>
										{children}
									</Button>
								)}
							/>
						))}
					</Flex>
				)}
			</Field>
			<Field
				label={formatMessage(i18n.feedbackCategory)}
				name="feedback-category"
				id="feedback-category"
			>
				{({ fieldProps }) => (
					<Inline space="space.050" data-name={fieldProps.id} xcss={feedbackCategoryContainer}>
						<CategoryPill
							categoryName="uiQuality"
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
						>
							<FormattedMessage {...i18n.userInterfaceQuality} />
						</CategoryPill>
						<CategoryPill
							categoryName="featureSuggestion"
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
						>
							<FormattedMessage {...i18n.featureSuggestion} />
						</CategoryPill>
						<CategoryPill
							categoryName="reportBug"
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
						>
							<FormattedMessage {...i18n.reportBug} />
						</CategoryPill>
					</Inline>
				)}
			</Field>
		</>
	);
};

type FeedbackCollectorButtonComponentProps = {
	flags: FlagsStateContainer;
	contentId: string;
};

const FeedbackCollectorButtonComponent: React.FC<FeedbackCollectorButtonComponentProps> = ({
	flags,
	contentId,
}) => {
	const { formatMessage, locale } = useIntl();
	const [isFeedbackCollectorOpen, setIsFeedbackCollectorOpen] = useState<boolean>(false);
	const [experienceRating, setExperienceRating] = useState<ExperienceRating>(
		ExperienceRating.Neutral,
	);
	const [selectedCategories, setSelectedCategories] = useState<Record<string, boolean>>({
		uiQuality: false,
		featureSuggestion: false,
		reportBug: false,
	});
	const { userId, displayName, cloudId, edition, environment } = useSessionData();

	const handleGiveFeedbackClick = () => {
		setIsFeedbackCollectorOpen(true);
	};

	const contentWrapperFeedbackContent = () => {
		return (
			<ContentWrapperCustomFeedbackContent
				experienceRating={experienceRating}
				setExperienceRating={setExperienceRating}
				selectedCategories={selectedCategories}
				setSelectedCategories={setSelectedCategories}
			/>
		);
	};

	const compileFeedbackMetadata = () => {
		const feedbackCategories = [];
		for (const key in selectedCategories) {
			if (selectedCategories[key]) {
				feedbackCategories.push(key);
			}
		}

		const feedbackMetadata = {
			location: window.location.hostname,
			userId: userId ?? '',
			cloudId: cloudId ?? '',
			contentId,
			edition: edition ?? '',
			environment: environment ?? '',
			'Experience Rating': experienceRating,
			'Feedback Categories': feedbackCategories.length ? feedbackCategories : undefined,
		};

		return JSON.stringify(feedbackMetadata, null, 2);
	};

	const onFeedbackCollectorClose = () => {
		setExperienceRating(ExperienceRating.Neutral);
		setSelectedCategories({
			uiQuality: false,
			featureSuggestion: false,
			reportBug: false,
		});
		setIsFeedbackCollectorOpen(false);
	};

	const onFeedbackSubmit = () => {
		void flags.showSuccessCircleFlag({
			id: 'object-sidebar-components.feedback-collector.success-flag',
			title: formatMessage(i18n.successFlagTitle),
			description: formatMessage(i18n.successFlagDescription),
			isAutoDismiss: true,
		});
	};

	return (
		<>
			<Tooltip position="left" content={formatMessage(i18n.feedbackButtonTooltip)}>
				<IconButton
					onClick={handleGiveFeedbackClick}
					appearance="subtle"
					shape={fg('ai-smart-button-team-25') ? 'default' : 'circle'}
					icon={MegaphoneIcon}
					label={formatMessage(i18n.feedbackButtonTooltip)}
					testId="sidebar-feedback-button"
					data-vc="object-sidebar-feedback-button"
				/>
			</Tooltip>
			{isFeedbackCollectorOpen && (
				<FeedbackCollector
					entrypointId={ENTRY_POINT_ID}
					locale={locale}
					atlassianAccountId={userId ?? ''}
					name={displayName ?? ''}
					showTypeField={false}
					feedbackTitle={formatMessage(i18n.feedbackDialogTitle)}
					customTextAreaLabel={formatMessage(i18n.tellUsMore)}
					customContent={contentWrapperFeedbackContent()}
					additionalFields={[
						{
							id: FEEDBACK_CONTEXT_ID,
							value: compileFeedbackMetadata(),
						},
					]}
					onClose={onFeedbackCollectorClose}
					onSubmit={onFeedbackSubmit}
				/>
			)}
		</>
	);
};

export const FeedbackCollectorButton = withFlags(FeedbackCollectorButtonComponent);
